import React from 'react'

import ACL from '../../components/ACL'
import Aim from './Aim'
import RoleEnum from '../../utils/Enum/RoleEnum'

class AimsIndex extends React.Component {
    render() {
        return (
            <ACL
                allowedRoles={[
                    RoleEnum.ROLE_USER,
                    RoleEnum.ROLE_MOD,
                    RoleEnum.ROLE_ADMIN,
                ]}
            >
                <Aim />
            </ACL>
        )
    }
}

export default AimsIndex
