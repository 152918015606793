import { IonButton, IonIcon } from '@ionic/react'
import { addOutline } from 'ionicons/icons'
import { FC, memo } from 'react'

type ButtonProps = {
    routerLink?: string
    disabled?: boolean
    onClick?: () => void
}

const AddDataButton: FC<ButtonProps> = ({ onClick, routerLink, disabled }) => {
    return (
        <IonButton
            routerLink={routerLink}
            onClick={onClick}
            disabled={disabled}
            shape="round"
            strong
        >
            <IonIcon slot="icon-only" icon={addOutline} />
        </IonButton>
    )
}

export default memo(AddDataButton)
