import React from 'react'

import ACL from '../../components/ACL'
import RoleEnum from '../../utils/Enum/RoleEnum'
import Dashboard from './Dashboard'

const DashboardIndex = (): JSX.Element => {
    return (
        <ACL
            allowedRoles={[
                RoleEnum.ROLE_USER,
                RoleEnum.ROLE_MOD,
                RoleEnum.ROLE_ADMIN,
            ]}
        >
            <Dashboard />
        </ACL>
    )
}

export default DashboardIndex
