import {
    createAsyncThunk,
    createEntityAdapter,
    createSlice,
    EntityId,
} from '@reduxjs/toolkit'
import { Flag } from './FlagType'
import { RootState } from '../../store'
import axiosInstance from '../../utils/ApiConfig'
import { normalize, schema } from 'normalizr'
import { Schemas } from '../../schemas'
import { FormikValues } from 'formik'
import { fetchCaseById } from '../Case/CaseSlice'

const flagAdapter = createEntityAdapter<Flag>({
    sortComparer: (a, b) => a.id - b.id,
})

export const fetchAllFlags = createAsyncThunk(
    'flag/fetchAll',
    async (params: { patientId: EntityId; caseId: EntityId }) => {
        const { patientId, caseId } = params

        const response = await axiosInstance.get(
            `/patients/${patientId}/cases/${caseId}/flags/`
        )
        const normalized = normalize<
            schema.Array<Flag>,
            {
                flag: Record<string, Flag>
            }
        >(response.data.data, Schemas.Flags)

        return { entities: normalized.entities, message: response.data.message }
    }
)

export const fetchFlagById = createAsyncThunk(
    'flag/fetchById',
    async (params: { id: EntityId; patientId: EntityId; caseId: EntityId }) => {
        const { id, patientId, caseId } = params

        const response = await axiosInstance.get(
            `/patients/${patientId}/cases/${caseId}/flags/${id}`
        )
        const normalized = normalize<
            schema.Entity<Flag>,
            {
                flag: Record<string, Flag>
            }
        >(response.data.data, Schemas.Flag)

        return { entities: normalized.entities, message: response.data.message }
    }
)

export const updateFlag = createAsyncThunk(
    'flag/update',
    async (params: {
        id: EntityId
        patientId: EntityId
        caseId: EntityId
        data: FormikValues
    }) => {
        const { id, data, caseId, patientId } = params
        const response = await axiosInstance.put(
            `/patients/${patientId}/cases/${caseId}/flags/${id}`,
            {
                ...data,
            }
        )
        const normalized = normalize<
            schema.Entity<Flag>,
            {
                flag: Record<string, Flag>
            }
        >(response.data.data, Schemas.Flag)

        return { entities: normalized.entities, message: response.data.message }
    }
)

export const createFlag = createAsyncThunk(
    'flag/create',
    async (params: {
        data: FormikValues
        patientId: EntityId
        caseId: EntityId
    }) => {
        const { data, patientId, caseId } = params
        const response = await axiosInstance.post(
            `/patients/${patientId}/cases/${caseId}/flags/`,
            {
                ...data,
            }
        )
        const normalized = normalize<
            schema.Entity<Flag>,
            {
                flag: Record<string, Flag>
            }
        >(response.data.data, Schemas.Flag)

        return { entities: normalized.entities, message: response.data.message }
    }
)

export const deleteFlagById = createAsyncThunk(
    'flag/delete',
    async (params: { id: EntityId; patientId: EntityId; caseId: EntityId }) => {
        const { id, patientId, caseId } = params

        const response = await axiosInstance.delete(
            `/patients/${patientId}/cases/${caseId}/flags/${id}`
        )

        return { id: id, message: response.data.message }
    }
)

const flagSlice = createSlice({
    name: 'flag',
    initialState: flagAdapter.getInitialState({}),
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchAllFlags.fulfilled, (state, action) => {
            const { flag } = action.payload.entities

            if (flag) {
                flagAdapter.upsertMany(state, flag)
            }
        })
        builder.addCase(fetchFlagById.fulfilled, (state, action) => {
            const { flag } = action.payload.entities

            if (flag) {
                flagAdapter.upsertMany(state, flag)
            }
        })
        builder.addCase(updateFlag.fulfilled, (state, action) => {
            const { flag } = action.payload.entities

            if (flag) {
                flagAdapter.upsertMany(state, flag)
            }
        })
        builder.addCase(createFlag.fulfilled, (state, action) => {
            const { flag } = action.payload.entities

            if (flag) {
                flagAdapter.upsertMany(state, flag)
            }
        })
        builder.addCase(deleteFlagById.fulfilled, (state, action) => {
            const { id } = action.payload

            flagAdapter.removeOne(state, +id)
        })
        builder.addCase(fetchCaseById.fulfilled, (state, action) => {
            const { flag } = action.payload.entities

            if (flag) {
                flagAdapter.upsertMany(state, flag)
            }
        })
    },
})

export const {
    selectAll: selectAllFlags,
    selectById: selectFlagById,
    selectIds: selectFlagIds,
} = flagAdapter.getSelectors<RootState>((state) => state.flag)

export default flagSlice.reducer
