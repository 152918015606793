import { FC } from 'react'
import MyAccordion from '../../../../components/MyAccordion'
import { selectTestEvaluationIds } from '../../../TestEvaluation/TestEvaluationSlice'
import { useAppSelector } from '../../../../hooks'
import AddDataButton from '../../../../components/AddData/AddDataButton'
import { useRouteMatch } from 'react-router'
import { IonList } from '@ionic/react'
import TestEvaluationItem from './TestEvaluationItem'

type TestEvaluationProps = {
    disabled: boolean
    dataLoading: boolean
}

const TestEvaluation: FC<TestEvaluationProps> = ({ disabled }) => {
    const testEvaluationIds = useAppSelector(selectTestEvaluationIds)
    const match = useRouteMatch()

    return (
        <MyAccordion title={`Testauswertungen (${testEvaluationIds.length})`}>
            <IonList>
                {testEvaluationIds.map((id) => (
                    <TestEvaluationItem key={id} testEvaluationId={id} />
                ))}
            </IonList>

            <div className="index-main">
                <p style={{ paddingTop: '16px' }}>Testauswertung hinzufügen</p>
                <AddDataButton
                    disabled={disabled}
                    routerLink={`${match.url}/testevaluations/new`}
                />
            </div>
        </MyAccordion>
    )
}

export default TestEvaluation
