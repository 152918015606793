import {
    IonButton,
    IonContent,
    IonHeader,
    IonItem,
    IonLabel,
    IonPage,
    IonTitle,
    IonToolbar,
    IonList,
    IonButtons,
    IonIcon,
    IonListHeader,
    IonBackButton,
    useIonAlert,
} from '@ionic/react'
import { addOutline } from 'ionicons/icons'
import { isEmpty } from 'lodash'
import { trashOutline } from 'ionicons/icons'
import { useCallback, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks'
import {
    deleteTestById,
    fetchAllTests,
    selectTestById,
    selectTestIds,
} from './TestSlice'
import { EntityId } from '@reduxjs/toolkit'
import AddDataButton from '../../components/AddData/AddDataButton'

type TestRowProps = {
    id: EntityId
}

const TestRow = (props: TestRowProps) => {
    const { id } = props
    const testData = useAppSelector((state) => selectTestById(state, +id))
    const [presentAlert] = useIonAlert()
    const dispatch = useAppDispatch()

    const handleDelete = useCallback(() => {
        dispatch(deleteTestById({ id: +id }))
    }, [dispatch, id])

    const handleClick = useCallback(
        (e: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) => {
            e.stopPropagation()
            e.preventDefault()
            presentAlert({
                header: `Wollen Sie ${testData.name} wirklich löschen?`,
                buttons: [
                    {
                        text: 'Abbrechen',
                        role: 'cancel',
                    },
                    {
                        text: 'Löschen',
                        role: 'delete',
                        handler: handleDelete,
                    },
                ],
            })
        },
        [handleDelete, presentAlert, testData.name]
    )

    if (!testData) {
        return null
    }

    return (
        <IonItem routerLink={`/tests/edit/${id}`}>
            <IonLabel>{testData.id + ' ' + testData.name}</IonLabel>
            <IonButton
                color="danger"
                fill="clear"
                slot="end"
                onClick={handleClick}
            >
                <IonIcon icon={trashOutline} />
            </IonButton>
        </IonItem>
    )
}

const Tests = () => {
    const testIds = useAppSelector(selectTestIds)
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(fetchAllTests())
    }, [dispatch])

    return (
        <IonList>
            <IonListHeader>
                <IonLabel>Test-Datenbank</IonLabel>
                <IonButton routerLink="/tests/new">
                    <IonIcon slot="icon-only" icon={addOutline}></IonIcon>
                </IonButton>
            </IonListHeader>
            {!isEmpty(testIds) ? (
                testIds.map((testId) => <TestRow key={testId} id={testId} />)
            ) : (
                <div className="index-main">
                    <p>Keine Tests vorhanden</p>
                    <AddDataButton routerLink="/tests/new" />
                </div>
            )}
        </IonList>
    )
}

const TestList = (): JSX.Element => {
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton
                            text=""
                            defaultHref={`/dashboard/`}
                        ></IonBackButton>
                    </IonButtons>
                    <IonTitle>Testübersicht</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <Tests />
            </IonContent>
        </IonPage>
    )
}

export default TestList
