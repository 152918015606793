import {
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar,
    IonCard,
    IonCardHeader,
    IonCardTitle,
} from '@ionic/react'

//import React from 'react'
import ACL from '../../components/ACL'
import RoleEnum from '../../utils/Enum/RoleEnum'
import { FC } from 'react'

const UserCard: FC = () => {
    return (
        <IonCard routerLink="/users">
            <IonCardHeader>
                <IonCardTitle>Benutzerübersicht</IonCardTitle>
            </IonCardHeader>
        </IonCard>
    )
}

const PatientCard: FC = () => {
    return (
        <IonCard routerLink="/patients">
            <IonCardHeader>
                <IonCardTitle>Patientenübersicht</IonCardTitle>
            </IonCardHeader>
        </IonCard>
    )
}

const TestCard: FC = () => {
    return (
        <IonCard routerLink="/tests/">
            <IonCardHeader>
                <IonCardTitle>Testübersicht</IonCardTitle>
            </IonCardHeader>
        </IonCard>
    )
}

//type ContentProps = { title: string, count: number }
const Content: FC = () => {
    return (
        <IonGrid>
            <IonRow>
                <ACL allowedRoles={[RoleEnum.ROLE_MOD, RoleEnum.ROLE_ADMIN]}>
                    <IonCol size="12" sizeMd="4">
                        <UserCard />
                    </IonCol>
                </ACL>
                <IonCol size="12" sizeMd="4">
                    <PatientCard />
                </IonCol>
                <IonCol size="12" sizeMd="4">
                    <TestCard />
                </IonCol>
            </IonRow>
        </IonGrid>
    )
}

const DashboardNew: FC = () => {
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Dashboard</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen color="light">
                <Content />
            </IonContent>
        </IonPage>
    )
}

export default DashboardNew
