import {
    IonButton,
    IonChip,
    IonCol,
    IonContent,
    IonIcon,
    IonPopover,
    IonText,
} from '@ionic/react'
import { FormikErrors, FormikValues } from 'formik'
import { FC, useCallback, useState } from 'react'
import { useAppSelector } from '../../hooks'
import { selectTestById } from '../Test/TestSlice'
import {
    checkmarkOutline,
    closeOutline,
    informationCircleOutline,
    removeOutline,
} from 'ionicons/icons'
import { intersection } from 'lodash'

const Popover: FC<{
    isOpen: boolean
    title: string
    content: string
    onDidDismiss: (event: CustomEvent) => void
}> = ({ title, content, isOpen, onDidDismiss }) => (
    <IonPopover
        isOpen={isOpen}
        onDidDismiss={onDidDismiss}
        side="left"
        alignment="center"
        className="popover-content"
    >
        <IonContent className="ion-padding">
            <div>
                <h3>{title}</h3>
                <p>{content}</p>
            </div>
        </IonContent>
    </IonPopover>
)

type TestRowProps = {
    testId: number
    values: FormikValues
    setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean | undefined
    ) => Promise<void | FormikErrors<FormikValues>>
}

const TestRow: FC<TestRowProps> = ({ testId, values, setFieldValue }) => {
    const [isPopoverOpen, setIsPopoverOpen] = useState(false)
    const test = useAppSelector((state) => selectTestById(state, testId))

    const handleClick = useCallback(() => {
        if (values.tests?.includes(testId)) {
            const newTests = values.tests.filter((x: number) => x !== testId)
            setFieldValue('tests', newTests)
            const rejectedTests = values?.rejectedTests
                ? [...values.rejectedTests]
                : []

            setFieldValue(
                'rejectedTests',
                intersection(newTests, rejectedTests)
            )
        } else {
            const newTests = values.tests ? [...values.tests, testId] : [testId]
            setFieldValue('tests', newTests)
        }
    }, [setFieldValue, testId, values.rejectedTests, values.tests])

    const handleRejectedClick = useCallback(() => {
        const rejectedTests = values?.rejectedTests
            ? [...values.rejectedTests]
            : []

        if (rejectedTests.includes(testId)) {
            return
        } else {
            rejectedTests.push(testId)
            setFieldValue('rejectedTests', rejectedTests)
        }
    }, [setFieldValue, testId, values?.rejectedTests])

    const handleAcceptedClick = useCallback(() => {
        const rejectedTests = values?.rejectedTests
            ? [...values.rejectedTests]
            : []

        if (rejectedTests.includes(testId)) {
            rejectedTests.splice(rejectedTests.indexOf(testId), 1)
            setFieldValue('rejectedTests', rejectedTests)
        } else {
            return
        }
    }, [setFieldValue, testId, values.rejectedTests])

    return (
        <>
            <IonCol size="8" style={{ display: 'flex', alignItems: 'center' }}>
                <IonChip
                    onClick={handleClick}
                    color={
                        values.tests?.includes(testId) ? 'success' : undefined
                    }
                    outline={!values.tests?.includes(testId)}
                >
                    <IonIcon
                        icon={
                            values.tests?.includes(testId)
                                ? checkmarkOutline
                                : removeOutline
                        }
                    />
                    <IonText>{test.name}</IonText>
                </IonChip>
                <IonButton
                    onClick={() => setIsPopoverOpen(true)}
                    shape="round"
                    fill="clear"
                >
                    <IonIcon slot="icon-only" icon={informationCircleOutline} />
                </IonButton>
                <Popover
                    isOpen={isPopoverOpen}
                    title={test.name}
                    content={test.shortDescription}
                    onDidDismiss={() => setIsPopoverOpen(false)}
                />
            </IonCol>
            <IonCol size="4" style={{ display: 'flex', alignItems: 'center' }}>
                {values.tests?.includes(testId) && (
                    <>
                        <IonChip
                            onClick={handleAcceptedClick}
                            color={
                                !values.rejectedTests?.includes(testId)
                                    ? 'success'
                                    : 'medium'
                            }
                        >
                            {!values.rejectedTests?.includes(testId) && (
                                <IonIcon icon={checkmarkOutline} />
                            )}
                            <IonText>
                                {!values.rejectedTests?.includes(testId)
                                    ? 'Test akzeptiert'
                                    : 'Test akzeptieren'}
                            </IonText>
                        </IonChip>
                        <IonChip
                            onClick={handleRejectedClick}
                            color={
                                values.rejectedTests?.includes(testId)
                                    ? 'danger'
                                    : 'medium'
                            }
                        >
                            {values.rejectedTests?.includes(testId) && (
                                <IonIcon icon={closeOutline} />
                            )}
                            <IonText>
                                {values.rejectedTests?.includes(testId)
                                    ? 'Test verworfen'
                                    : 'Test verwerfen'}
                            </IonText>
                        </IonChip>
                    </>
                )}
            </IonCol>
        </>
    )
}

export default TestRow
