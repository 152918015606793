import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonInput,
    IonItem,
    IonPage,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToolbar,
} from '@ionic/react'
import './Register.css'
import React from 'react'
import { Formik, FormikProps, FormikValues } from 'formik'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { ConnectedProps, connect } from 'react-redux'
import { isEqual } from 'lodash'
import { RootState } from '../../store'
import { IonSelectCustomEvent, SelectChangeEventDetail } from '@ionic/core'
import RoleEnum from '../../utils/Enum/RoleEnum'
import { createUser } from '../User/UserSlice'

type RegisterProps = {}

type RegisterState = {}

type RouterProps = RouteComponentProps

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = RegisterProps & PropsFromRedux & RouterProps

class Register extends React.Component<Props, RegisterState> {
    handleSubmitForm = async (values: FormikValues) => {
        const { createUser, history } = this.props

        const response = await createUser({ data: values }).unwrap()

        if (response.statusCode === 201) {
            setTimeout(() => {
                history.push('/users')
            }, 1000)
        }
    }

    handleRoleChange = (
        e: IonSelectCustomEvent<SelectChangeEventDetail>,
        props: FormikProps<FormikValues>
    ) => {
        const { setFieldValue } = props

        if (isEqual(e.detail.value, RoleEnum.ROLE_ADMIN)) {
            setFieldValue('role', RoleEnum.ROLE_ADMIN, false)
        } else if (isEqual(e.detail.value, RoleEnum.ROLE_MOD)) {
            setFieldValue('role', RoleEnum.ROLE_MOD, false)
        } else {
            setFieldValue('role', RoleEnum.ROLE_USER, false)
        }
    }

    renderRoleSelect = (props: FormikProps<FormikValues>) => {
        const { user } = this.props
        let value

        if (props.values.role === RoleEnum.ROLE_ADMIN) {
            value = RoleEnum.ROLE_ADMIN
        } else if (props.values.role === RoleEnum.ROLE_MOD) {
            value = RoleEnum.ROLE_MOD
        } else {
            value = RoleEnum.ROLE_USER
        }

        return (
            <IonItem>
                <IonSelect
                    placeholder="Rolle"
                    interfaceOptions={{ header: 'Rolle' }}
                    onIonChange={(e) => this.handleRoleChange(e, props)}
                    value={value}
                >
                    {user!.role === RoleEnum.ROLE_ADMIN && (
                        <IonSelectOption value={RoleEnum.ROLE_ADMIN}>
                            Admin
                        </IonSelectOption>
                    )}
                    <IonSelectOption value={RoleEnum.ROLE_MOD}>
                        Moderator
                    </IonSelectOption>
                    <IonSelectOption value={RoleEnum.ROLE_USER}>
                        Student
                    </IonSelectOption>
                </IonSelect>
            </IonItem>
        )
    }

    render() {
        const { user } = this.props
        const initialValues = { role: RoleEnum.ROLE_USER } as FormikValues

        if (!user) {
            return null
        }

        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonBackButton
                                text=""
                                defaultHref={`/users/`}
                            ></IonBackButton>
                        </IonButtons>
                        <IonTitle>Benutzer erstellen</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent fullscreen>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={this.handleSubmitForm}
                    >
                        {(props) => {
                            console.log(props)
                            return (
                                <form
                                    onSubmit={props.handleSubmit}
                                    className="full-width"
                                >
                                    <IonGrid
                                        className="register-container"
                                        justify-content-center
                                    >
                                        <IonRow>
                                            <IonCol>
                                                <IonItem>
                                                    <IonInput
                                                        fill="outline"
                                                        label="Vorname"
                                                        labelPlacement="floating"
                                                        name={'firstName'}
                                                        onIonChange={
                                                            props.handleChange
                                                        }
                                                    />
                                                </IonItem>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <IonItem>
                                                    <IonInput
                                                        fill="outline"
                                                        label="Nachname"
                                                        labelPlacement="floating"
                                                        name={'lastName'}
                                                        onIonChange={
                                                            props.handleChange
                                                        }
                                                    />
                                                </IonItem>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <IonItem>
                                                    <IonInput
                                                        fill="outline"
                                                        label="E-Mail"
                                                        labelPlacement="floating"
                                                        name={'email'}
                                                        onIonChange={
                                                            props.handleChange
                                                        }
                                                    />
                                                </IonItem>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <IonItem>
                                                    <IonInput
                                                        fill="outline"
                                                        label="Passwort"
                                                        labelPlacement="floating"
                                                        name={'password'}
                                                        onIonChange={
                                                            props.handleChange
                                                        }
                                                        type="password"
                                                    />
                                                </IonItem>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                {this.renderRoleSelect(props)}
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <IonButton
                                                    disabled={
                                                        props.isSubmitting
                                                    }
                                                    expand="block"
                                                    shape="round"
                                                    color="primary"
                                                    type="submit"
                                                    // onClick={() =>
                                                    //     props.handleSubmit()
                                                    // }
                                                >
                                                    Nutzer erstellen
                                                </IonButton>
                                            </IonCol>
                                        </IonRow>
                                        {/* <IonRow>
                                        <IonCol>
                                            <IonButton
                                                disabled={props.isSubmitting}
                                                expand="block"
                                                shape="round"
                                                color="primary"
                                                fill="outline"
                                                onClick={() => {
                                                    this.props.history.push(
                                                        '/login'
                                                    )
                                                }}
                                            >
                                                Zurück zum Login
                                            </IonButton>
                                        </IonCol>
                                    </IonRow> */}
                                    </IonGrid>
                                </form>
                            )
                        }}
                    </Formik>
                </IonContent>
            </IonPage>
        )
    }
}

const mapState = (state: RootState) => {
    const {
        session: { user },
    } = state

    return {
        user: user,
    }
}

const mapDispatch = { createUser }

const connector = connect(mapState, mapDispatch)

export default withRouter(connector(Register))
