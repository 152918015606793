import { get } from '../data/IonicStorage'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { IApiError, IApiResponse } from './Interfaces/ApiInterface'
import { store } from '../index'
import { addToast } from '../components/Toasts/toastSlice'
import { IToast } from './Interfaces/ToastInterface'

const getJwt = async () => {
    return await get('jwt')
}

// export const getHeader = async () => {
//   const token = await getJwt();
//   const axiosConfig: AxiosRequestConfig = {
//     headers: { Authorization: `Bearer ${token}` },
//     withCredentials: true,
//   };
//   return axiosConfig.headers; // Nur die Header zurückgeben
// };

export const API_ROOT =
    process.env.NODE_ENV !== 'production'
        ? 'http://localhost:4000/api'
        : 'https://asa.gw.hs-fulda.de/api'

const axiosInstance = axios.create({
    baseURL: API_ROOT,
})

// Fügen Sie einen Interceptor hinzu, um den Header hinzuzufügen
axiosInstance.interceptors.request.use(
    async (config) => {
        const token = await getJwt()
        config.headers['Authorization'] = `Bearer ${token}`
        config.withCredentials = true

        return config
    },
    (error) => {
        // Hier können Sie Fehler behandeln, wenn nötig
        return Promise.reject(error)
    }
)

axiosInstance.interceptors.response.use(
    (response: AxiosResponse<IApiResponse>) => {
        if (response.data.message?.toast) {
            const toast: IToast = {
                message: response.data.message.text,
                type: response.data.message.type,
                timestamp: new Date().getTime(),
            }
            store.dispatch(addToast(toast))
        }
        return response
    },
    (error: AxiosError<IApiError>) => {
        if (error.response?.data.message?.toast) {
            const toast: IToast = {
                message: error.response.data.message.text,
                type: error.response.data.message.type,
                timestamp: new Date().getTime(),
            }
            store.dispatch(addToast(toast))
        }
        return Promise.reject(error)
    }
)

export default axiosInstance
