import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { ConnectedProps, connect } from 'react-redux'
import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonCheckbox,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonItem,
    IonLabel,
    IonList,
    IonPage,
    IonRow,
    IonSpinner,
    IonTextarea,
    IonTitle,
    IonToolbar,
} from '@ionic/react'
import { Formik, FormikProps, FormikValues } from 'formik'
import { createAim, selectAimById, updateAim } from './AimSlice'
import { fetchCaseById, selectCaseById } from '../Case/CaseSlice'
import { RootState } from '../../store'

type AimEditProps = {}

type AimEditState = {
    dataLoading: boolean
    initialDataLoading: boolean
}

type RouterProps = RouteComponentProps<{
    patientId: string
    aimId: string
    caseId: string
}>

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = AimEditProps & RouterProps & PropsFromRedux

class AimEdit extends React.Component<Props, AimEditState> {
    state = {
        initialDataLoading: true,
        dataLoading: false,
    }

    componentDidMount = async () => {
        const { fetchCaseById, caseId, patientId } = this.props

        this.setState({ initialDataLoading: true })
        await fetchCaseById({ id: caseId, patientId })
        this.setState({ initialDataLoading: false })
    }

    handleSubmitForm = async (values: FormikValues) => {
        const {
            updateAim,
            createAim,
            fetchCaseById,
            isNewAim,
            aimId,
            history,
            match,
            caseId,
            patientId,
        } = this.props

        this.setState({ dataLoading: true })
        try {
            if (isNewAim) {
                await createAim({ data: values, caseId, patientId })
            } else {
                await updateAim({ data: values, id: aimId, caseId, patientId })
            }
            await fetchCaseById({ id: caseId, patientId })
            this.setState({ dataLoading: false }, () => {
                history.push(
                    `/patients/${match.params.patientId}/cases/${match.params.caseId}`
                )
            })
        } catch (err) {
            console.error(err)
        }
    }

    renderFieldset = (props: FormikProps<FormikValues>) => {
        const { setFieldValue, values, handleChange, handleSubmit } = props
        const { dataLoading } = this.state

        return (
            <IonGrid>
                <IonRow>
                    <IonLabel>
                        <h1>Art des Ziels</h1>
                    </IonLabel>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonList>
                            <IonItem
                                lines="none"
                                key={'therapeut'}
                                onClick={() => {
                                    setFieldValue('type', 'therapeut')
                                }}
                            >
                                <IonCheckbox
                                    labelPlacement="end"
                                    checked={values?.type === 'therapeut'}
                                    justify="start"
                                >
                                    Therapeutenziel
                                </IonCheckbox>
                            </IonItem>
                            <IonItem
                                lines="none"
                                key={'patient'}
                                onClick={() => {
                                    setFieldValue('type', 'patient')
                                }}
                            >
                                <IonCheckbox
                                    slot="start"
                                    checked={values?.type === 'patient'}
                                    labelPlacement="end"
                                    justify="start"
                                >
                                    Patientenziel
                                </IonCheckbox>
                            </IonItem>
                        </IonList>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem>
                            <IonTextarea
                                name="description"
                                label="Beschreibung"
                                labelPlacement="floating"
                                autoGrow={true}
                                onIonChange={handleChange}
                                value={values.description ?? ''}
                            ></IonTextarea>
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonLabel>
                        <h1>SMART</h1>
                    </IonLabel>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem>
                            <IonTextarea
                                name="specific"
                                label="Spezifisch"
                                labelPlacement="floating"
                                autoGrow={true}
                                onIonChange={handleChange}
                                value={values.specific ?? ''}
                            ></IonTextarea>
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem>
                            <IonTextarea
                                name="measurable"
                                label="Messbar"
                                labelPlacement="floating"
                                autoGrow={true}
                                onIonChange={handleChange}
                                value={values.measurable ?? ''}
                            ></IonTextarea>
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem>
                            <IonTextarea
                                name="achievable"
                                label="Ausführbar"
                                labelPlacement="floating"
                                autoGrow={true}
                                onIonChange={handleChange}
                                value={values.achievable ?? ''}
                            ></IonTextarea>
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem>
                            <IonTextarea
                                name="relevant"
                                label="Realistisch"
                                labelPlacement="floating"
                                autoGrow={true}
                                onIonChange={handleChange}
                                value={values.relevant ?? ''}
                            ></IonTextarea>
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem>
                            <IonTextarea
                                label="Terminierbar"
                                labelPlacement="floating"
                                name="timebound"
                                autoGrow={true}
                                onIonChange={handleChange}
                                value={values.timebound ?? ''}
                            ></IonTextarea>
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonButton onClick={() => handleSubmit()}>
                            {dataLoading ? <IonSpinner /> : 'Speichern'}
                        </IonButton>
                    </IonCol>
                </IonRow>
            </IonGrid>
        )
    }

    render() {
        const { isNewAim, caseId, aimById, match, thisCase } = this.props
        const { initialDataLoading } = this.state

        if (initialDataLoading) {
            return null
        }

        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar mode="ios">
                        <IonButtons slot="start">
                            <IonBackButton
                                text=""
                                defaultHref={`/patients/${match.params.patientId}/cases/${match.params.caseId}`}
                            ></IonBackButton>
                        </IonButtons>
                        <IonTitle>{isNewAim ? 'Neues Ziel' : 'Ziele'}</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent scrollY={false}>
                    <IonGrid style={{ height: '100%' }}>
                        <IonRow style={{ height: '100%' }}>
                            <IonCol size="12" size-sm="4">
                                <IonContent>
                                    <IonLabel>
                                        <h1>Anamnestische Fallanalyse</h1>
                                    </IonLabel>
                                    <p>
                                        {thisCase?.['anamnesticCaseAnalysis'] ??
                                            'Keine Anamnestische Fallanalyse angegeben'}
                                    </p>
                                </IonContent>
                            </IonCol>
                            <IonCol size="12" size-sm="8">
                                <IonContent>
                                    <Formik
                                        initialValues={
                                            isNewAim
                                                ? ({
                                                      caseId: caseId,
                                                  } as FormikValues)
                                                : { ...aimById }
                                        }
                                        onSubmit={this.handleSubmitForm}
                                        id="main"
                                    >
                                        {(props) => (
                                            <form
                                                onSubmit={props.handleSubmit}
                                                className="full-width"
                                            >
                                                {this.renderFieldset(props)}
                                            </form>
                                        )}
                                    </Formik>
                                </IonContent>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonContent>
            </IonPage>
        )
    }
}

const makeMapState = () => {
    return (state: RootState, ownProps: RouterProps) => {
        const match = ownProps.match
        const aimId = parseInt(match.params.aimId)
        const caseId = parseInt(match.params.caseId)
        const patientId = parseInt(match.params.patientId)
        const aimById = selectAimById(state, aimId)
        const caseById = selectCaseById(state, caseId)

        return {
            match: match,
            patientId: patientId,
            aimId: aimId,
            caseId: caseId,
            isNewAim: match.params.aimId === 'new',
            aimById: aimById,
            thisCase: caseById,
        }
    }
}

const mapDispatch = { createAim, updateAim, fetchCaseById }

const connector = connect(makeMapState, mapDispatch)

export default withRouter(connector(AimEdit))
