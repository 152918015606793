import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import {
    IonIcon,
    IonLabel,
    IonRouterOutlet,
    IonTabBar,
    IonTabButton,
    IonTabs,
} from '@ionic/react'
import Index from '../../../pages/Index/Index'

import UserIndex from '../../../pages/User/UserIndex'
import DashboardIndex from '../../../pages/Dashboard/DashboardIndex'

import PatientIndex from '../../../pages/Patient/PatientIndex'
import TestIndex from '../../../pages/Test/TestIndex'

import {
    accessibilityOutline,
    gridOutline,
    personOutline,
    walkOutline,
} from 'ionicons/icons'
import { useAppSelector } from '../../../hooks'

const MainTabs: React.FC = () => {
    const userId = useAppSelector((state) => state.session?.user?.id)

    return (
        <IonTabs>
            <IonRouterOutlet id="main-content" animated>
                <Route exact path="/" component={Index} />
                <Route path="/dashboard" component={DashboardIndex} />
                <Route path="/users" component={UserIndex} />
                <Route path="/patients" component={PatientIndex} />

                <Route path="/tests" component={TestIndex} />
                <Redirect exact from="/not-authorized" to="/login" />
            </IonRouterOutlet>

            <IonTabBar slot="bottom">
                <IonTabButton tab="dashboard" href="/dashboard">
                    <IonIcon icon={gridOutline} />
                    <IonLabel>Dashboard</IonLabel>
                </IonTabButton>

                <IonTabButton tab="patients" href="/patients">
                    <IonIcon icon={accessibilityOutline} />
                    <IonLabel>Patienten</IonLabel>
                </IonTabButton>

                <IonTabButton tab="tests" href="/tests">
                    <IonIcon icon={walkOutline} />
                    <IonLabel>Tests</IonLabel>
                </IonTabButton>

                <IonTabButton tab="users" href={'/users/' + userId}>
                    <IonIcon icon={personOutline} />
                    <IonLabel>Profil</IonLabel>
                </IonTabButton>

                {/* <IonTabButton tab="logout" href="/search">
                                        <IonIcon icon={search} />
                                        <IonLabel>Logout</IonLabel>
                                    </IonTabButton> */}
            </IonTabBar>
        </IonTabs>
    )
}

export default MainTabs
