import React from 'react'
import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonContent,
    IonIcon,
    IonInput,
    IonItem,
    IonList,
    IonPage,
} from '@ionic/react'
import { Formik } from 'formik'
import { useAppDispatch } from '../../hooks'
import { useHistory } from 'react-router-dom'
import { loginUser } from '../Auth/SessionSlice'
import { logInOutline } from 'ionicons/icons'

export type LoginForm = {
    username?: string
    password?: string
}

const Login = (): JSX.Element => {
    const history = useHistory()
    const dispatch = useAppDispatch()

    const handleSubmitForm = async (values: LoginForm) => {
        try {
            const response = await dispatch(loginUser(values))
            if (!response.type.endsWith('rejected')) {
                history.push('/dashboard')
            }
        } catch (error) {
            console.log('login error', error)
        }
    }

    const initialValues = {}

    return (
        <IonPage>
            {/*<IonHeader>
                <IonToolbar>
                    <IonTitle>Login</IonTitle>
                </IonToolbar>
            </IonHeader>*/}
            <IonContent fullscreen color="light">
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmitForm}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        /* and other goodies */
                    }) => (
                        <form
                            onSubmit={handleSubmit}
                            style={{
                                height: '100%',
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <IonCard
                                style={{
                                    maxWidth: '500px',
                                    width: '100%',
                                }}
                            >
                                <IonCardHeader className="ion-align-items-center">
                                    <IonCardTitle>Login</IonCardTitle>
                                    <IonCardSubtitle>
                                        Med-Educate
                                    </IonCardSubtitle>
                                </IonCardHeader>
                                <IonCardContent>
                                    <IonList>
                                        <IonItem lines="full">
                                            <IonInput
                                                name="email"
                                                label="E-Mail"
                                                labelPlacement="floating"
                                                onIonChange={handleChange}
                                            ></IonInput>
                                        </IonItem>
                                    </IonList>
                                    <IonList>
                                        <IonItem lines="full">
                                            <IonInput
                                                type="password"
                                                name="password"
                                                label="Passwort"
                                                labelPlacement="floating"
                                                onIonChange={handleChange}
                                            ></IonInput>
                                        </IonItem>
                                    </IonList>
                                </IonCardContent>
                                <div style={{ padding: '20px' }}>
                                    <IonButton shape="round" type="submit">
                                        <IonIcon
                                            slot="start"
                                            icon={logInOutline}
                                        ></IonIcon>
                                        Login
                                    </IonButton>
                                </div>
                            </IonCard>
                        </form>
                    )}
                </Formik>
                <div
                    style={{
                        position: 'absolute',
                        bottom: 0,
                        right: 0,
                        opacity: 0.3,
                    }}
                >
                    {window.location.hostname}
                </div>
            </IonContent>
        </IonPage>
    )
}

export default Login
