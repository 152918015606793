import React, { useEffect, useState } from 'react'
import { Route } from 'react-router-dom'
import { IonApp } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
import { useDispatch, useSelector } from 'react-redux'
import { createStore } from './data/IonicStorage'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'

/* Theme variables */
import './theme/variables.css'

import { setupIonicReact } from '@ionic/react'
import Login from './pages/Login/Login'
import Index from './pages/Index/Index'

import Toasts from './components/Toasts/Toasts'

import './app.css'

import { AppDispatch, RootState } from './store'
import { fetchSession } from './pages/Auth/SessionSlice'
import MainTabs from './pages/Case/components/MainTabs'

setupIonicReact({
    //we may need this later
    //mode: 'md'
    innerHTMLTemplatesEnabled: true,
})

const setupStore = async () => {
    await createStore('med-educate-db')
}

const App = (): JSX.Element => {
    const [loading, setLoading] = useState(true)
    const dispatch = useDispatch<AppDispatch>()
    const loggedIn = useSelector((state: RootState) => state.session.loggedIn)

    useEffect(() => {
        setLoading(true)
        const setup = async () => {
            await setupStore()
            await dispatch(fetchSession())
        }
        try {
            setup()
            setLoading(false)
        } catch (error) {
            console.error('error', error)
        }
    }, [dispatch])

    return (
        <IonApp>
            {!loading && (
                <>
                    <Toasts />
                    <IonReactRouter>
                        <Route
                            path="/"
                            component={loggedIn ? MainTabs : Index}
                        />
                        <Route path="/login" component={Login} />
                    </IonReactRouter>
                </>
            )}
        </IonApp>
    )
}

export default App
