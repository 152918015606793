import {
    createEntityAdapter,
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit'
import { RootState } from '../../store'
import { IToast } from '../../utils/Interfaces/ToastInterface'

const toastAdapter = createEntityAdapter({
    selectId: (toast: IToast) => toast.timestamp,
    sortComparer: (a, b) => a.timestamp - b.timestamp,
})

const toastSlice = createSlice({
    name: 'toasts',
    initialState: toastAdapter.getInitialState({}),
    reducers: {
        addToast: (state, action: PayloadAction<IToast>) => {
            toastAdapter.addOne(state, action.payload)
        },
        removeToast: (state, action: PayloadAction<IToast>) => {
            toastAdapter.removeOne(state, action.payload.timestamp)
        },
    },
})

export const { addToast, removeToast } = toastSlice.actions

export const {
    selectAll: selectAllToasts,
    selectById: selectToastById,
    selectIds,
} = toastAdapter.getSelectors<RootState>((state) => state.toasts)

export default toastSlice.reducer
