import {
    EntityId,
    createAsyncThunk,
    createEntityAdapter,
    createSlice,
} from '@reduxjs/toolkit'
import { Documentation } from './DocumentationType'
import { RootState } from '../../store'
import axiosInstance from '../../utils/ApiConfig'
import { normalize, schema } from 'normalizr'
import { Schemas } from '../../schemas'
import { FormikValues } from 'formik'
import { fetchCaseById } from '../Case/CaseSlice'
import { Test } from '../Test/TestType'

const documentationAdapter = createEntityAdapter<Documentation>({
    sortComparer: (a, b) => a.id - b.id,
})

export const fetchAllDocumentations = createAsyncThunk(
    'documentation/fetchAll',
    async (params: { patientId: EntityId; caseId: EntityId }) => {
        const { caseId, patientId } = params

        const response = await axiosInstance.get(
            `/patients/${patientId}/cases/${caseId}/documentations/`
        )
        const normalized = normalize<
            schema.Array<Documentation>,
            {
                documentation: Record<string, Documentation>
            }
        >(response.data.data, Schemas.Documentations)

        return { entities: normalized.entities, message: response.data.message }
    }
)

export const fetchDocumentationById = createAsyncThunk(
    'documentation/fetchById',
    async (params: { id: EntityId; patientId: EntityId; caseId: EntityId }) => {
        const { id, caseId, patientId } = params

        const response = await axiosInstance.get(
            `/patients/${patientId}/cases/${caseId}/documentations/${id}`
        )
        const normalized = normalize<
            schema.Entity<Documentation>,
            {
                documentation: Record<string, Documentation>
            }
        >(response.data.data, Schemas.Documentation)

        return { entities: normalized.entities, message: response.data.message }
    }
)

export const updateDocumentation = createAsyncThunk(
    'documentation/update',
    async (params: {
        id: EntityId
        caseId: EntityId
        patientId: EntityId
        data: FormikValues
    }) => {
        const { id, data, caseId, patientId } = params
        const response = await axiosInstance.put(
            `/patients/${patientId}/cases/${caseId}/documentations/${id}`,
            {
                ...data,
            }
        )
        const normalized = normalize<
            schema.Entity<Documentation>,
            {
                documentation: Record<string, Documentation>
            }
        >(response.data.data, Schemas.Documentation)

        return { entities: normalized.entities, message: response.data.message }
    }
)

export const createDocumentation = createAsyncThunk(
    'documentation/create',
    async (params: {
        data: FormikValues
        patientId: EntityId
        caseId: EntityId
    }) => {
        const { data, caseId, patientId } = params
        const response = await axiosInstance.post(
            `/patients/${patientId}/cases/${caseId}/documentations/`,
            {
                ...data,
            }
        )
        const normalized = normalize<
            schema.Entity<Documentation>,
            {
                documentation: Record<string, Documentation>
                test: Record<string, Test>
            }
        >(response.data.data, Schemas.Documentation)

        return { entities: normalized.entities, message: response.data.message }
    }
)

export const deleteDocumentationById = createAsyncThunk(
    'documentation/delete',
    async (params: { id: EntityId; patientId: EntityId; caseId: EntityId }) => {
        const { id, caseId, patientId } = params

        const response = await axiosInstance.delete(
            `/patients/${patientId}/cases/${caseId}/documentations/${id}`
        )

        return { id: id, message: response.data.message }
    }
)

const documentationSlice = createSlice({
    name: 'documentation',
    initialState: documentationAdapter.getInitialState({}),
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchAllDocumentations.fulfilled, (state, action) => {
            const { documentation } = action.payload.entities

            if (documentation) {
                documentationAdapter.upsertMany(state, documentation)
            }
        })
        builder.addCase(fetchDocumentationById.fulfilled, (state, action) => {
            const { documentation } = action.payload.entities

            if (documentation) {
                documentationAdapter.upsertMany(state, documentation)
            }
        })
        builder.addCase(deleteDocumentationById.fulfilled, (state, action) => {
            const { id } = action.payload

            documentationAdapter.removeOne(state, +id)
        })
        builder.addCase(fetchCaseById.fulfilled, (state, action) => {
            const { documentation } = action.payload.entities

            if (documentation) {
                documentationAdapter.upsertMany(state, documentation)
            }
        })
    },
})

export const {
    selectAll: selectAllDocumentations,
    selectById: selectDocumentationById,
    selectIds: selectDocumentationIds,
} = documentationAdapter.getSelectors<RootState>((state) => state.documentation)

export default documentationSlice.reducer
