import React, { Component } from 'react'
import {
    IonCard,
    IonCardContent,
    IonIcon,
    IonAccordionGroup,
    IonAccordion,
    IonItem,
    IonLabel,
} from '@ionic/react'
import { checkmarkCircleOutline, closeCircleOutline } from 'ionicons/icons'

type Props = {
    showIcon?: boolean
    checked?: boolean
    title: string
    children: React.ReactNode
}

type State = {
    accordionOpen: boolean
}

class MyAccordion extends Component<Props, State> {
    state: State = {
        accordionOpen: false,
    }

    render() {
        const { showIcon, checked } = this.props

        return (
            <IonCard className="full-width-card">
                {/* <IonCardHeader>
                    <IonCardTitle
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                        }}
                    >
                        <IonButton
                            style={{ visibility: 'hidden' }}
                            fill="clear"
                        >
                            <IonIcon slot="icon-only" icon={chevronUpOutline} />
                        </IonButton>
                        <span>{this.props.title}</span>
                        <IonButton
                            fill="clear"
                            className="no-ripple-no-hover"
                            style={
                                !this.state.accordionOpen
                                    ? {
                                          transform: 'rotate(-180deg)',
                                          transition: 'transform 0.5s linear',
                                      }
                                    : {
                                          transform: 'rotate(0deg)',
                                          transition: 'transform 0.5s linear',
                                      }
                            }
                            onClick={() =>
                                this.setState({
                                    accordionOpen: !this.state.accordionOpen,
                                })
                            }
                        >
                            <IonIcon slot="icon-only" icon={chevronUpOutline} />
                        </IonButton>
                    </IonCardTitle>
                </IonCardHeader> */}
                <IonCardContent
                // style={
                //     !this.state.accordionOpen
                //         ? {
                //               clip: 'rect(auto, auto, 0, auto)',
                //               transitionProperty: 'clip',
                //               transitionDuration: '0.5s',
                //               transitionTimingFunction: 'linear',
                //           }
                //         : {
                //               clip: 'rect(auto, auto, 10rem, auto)',

                //               transitionProperty: 'clip',
                //               transitionDuration: '0.5s',
                //               transitionTimingFunction: 'linear',
                //           }
                // }
                // style={
                //     !this.state.accordionOpen
                //         ? {
                //               maxHeight: 0,
                //               transition:
                //                   'max-height 0.5s linear, padding 0.5s linear',
                //               padding: 0,
                //           }
                //         : {
                //               maxHeight: 1000,
                //               transition:
                //                   'max-height 0.5s linear, padding 0.5s linear',
                //           }
                // }
                >
                    <IonAccordionGroup>
                        <IonAccordion value="first">
                            <IonItem
                                slot="header"
                                style={{ textAlign: 'center' }}
                            >
                                <IonLabel>
                                    {this.props.title}{' '}
                                    {showIcon && (
                                        <span
                                            style={{ verticalAlign: 'middle' }}
                                        >
                                            <IonIcon
                                                color={
                                                    checked
                                                        ? 'success'
                                                        : 'warning'
                                                }
                                                icon={
                                                    checked
                                                        ? checkmarkCircleOutline
                                                        : closeCircleOutline
                                                }
                                            />
                                        </span>
                                    )}
                                </IonLabel>
                            </IonItem>
                            <div slot="content">{this.props.children}</div>
                        </IonAccordion>
                    </IonAccordionGroup>
                </IonCardContent>
            </IonCard>
        )
    }
}

export default MyAccordion
