import {
    createAsyncThunk,
    createEntityAdapter,
    createSlice,
} from '@reduxjs/toolkit'
import { Test } from './TestType'
import { RootState } from '../../store'
import axiosInstance from '../../utils/ApiConfig'
import { normalize, schema } from 'normalizr'
import { Schemas } from '../../schemas'
import { FormikValues } from 'formik'
import { fetchCaseById } from '../Case/CaseSlice'
import { createDocumentation } from '../Documentation/DocumentationSlice'
import { fetchTestSelectionById } from '../TestSelection/TestSelectionSlice'
import {
    fetchAllTestEvaluations,
    fetchTestEvaluationById,
    updateTestEvaluation,
} from '../TestEvaluation/TestEvaluationSlice'

const testAdapter = createEntityAdapter<Test>({
    sortComparer: (a, b) => a.id - b.id,
})

export const fetchAllTests = createAsyncThunk('test/fetchAll', async () => {
    const response = await axiosInstance.get('/tests/')
    const normalized = normalize<
        schema.Array<Test>,
        {
            test: Record<string, Test>
        }
    >(response.data.data, Schemas.Tests)

    return { entities: normalized.entities, message: response.data.message }
})

export const fetchTestById = createAsyncThunk(
    'test/fetchById',
    async (params: { id: number }) => {
        const { id } = params

        const response = await axiosInstance.get(`/tests/${id}`)
        const normalized = normalize<
            schema.Entity<Test>,
            {
                test: Record<string, Test>
            }
        >(response.data.data, Schemas.Test)

        return { entities: normalized.entities, message: response.data.message }
    }
)

export const updateTest = createAsyncThunk(
    'test/update',
    async (params: { id: number | string; data: FormikValues }) => {
        const { id, data } = params
        const response = await axiosInstance.put(`/tests/${id}`, {
            ...data,
        })
        const normalized = normalize<
            schema.Entity<Test>,
            {
                test: Record<string, Test>
            }
        >(response.data.data, Schemas.Test)

        return { entities: normalized.entities, message: response.data.message }
    }
)

export const createTest = createAsyncThunk(
    'test/create',
    async (params: { data: FormikValues }) => {
        const { data } = params
        const response = await axiosInstance.post('/tests/', {
            ...data,
        })
        const normalized = normalize<
            schema.Entity<Test>,
            {
                test: Record<string, Test>
            }
        >(response.data.data, Schemas.Test)

        return { entities: normalized.entities, message: response.data.message }
    }
)

export const deleteTestById = createAsyncThunk(
    'test/delete',
    async (params: { id: number }) => {
        const { id } = params

        const response = await axiosInstance.delete(`/tests/${id}`)

        return { id: id, message: response.data.message }
    }
)

const testSlice = createSlice({
    name: 'test',
    initialState: testAdapter.getInitialState({}),
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchAllTests.fulfilled, (state, action) => {
            const { test } = action.payload.entities

            if (test) {
                testAdapter.upsertMany(state, test)
            }
        })
        builder.addCase(fetchTestById.fulfilled, (state, action) => {
            const { test } = action.payload.entities

            if (test) {
                testAdapter.upsertMany(state, test)
            }
        })
        builder.addCase(createTest.fulfilled, (state, action) => {
            const { test } = action.payload.entities

            if (test) {
                testAdapter.upsertMany(state, test)
            }
        })
        builder.addCase(deleteTestById.fulfilled, (state, action) => {
            const { id } = action.payload

            testAdapter.removeOne(state, id)
        })
        builder.addCase(fetchCaseById.fulfilled, (state, action) => {
            const { test } = action.payload.entities

            if (test) {
                testAdapter.upsertMany(state, test)
            }
        })
        builder.addCase(createDocumentation.fulfilled, (state, action) => {
            const { test } = action.payload.entities

            if (test) {
                testAdapter.upsertMany(state, test)
            }
        })
        builder.addCase(fetchTestSelectionById.fulfilled, (state, action) => {
            const { test } = action.payload.entities

            if (test) {
                testAdapter.upsertMany(state, test)
            }
        })
        builder.addCase(updateTestEvaluation.fulfilled, (state, action) => {
            const { test } = action.payload.entities

            if (test) {
                testAdapter.upsertMany(state, test)
            }
        })
        builder.addCase(fetchTestEvaluationById.fulfilled, (state, action) => {
            const { test } = action.payload.entities

            if (test) {
                testAdapter.upsertMany(state, test)
            }
        })
        builder.addCase(fetchAllTestEvaluations.fulfilled, (state, action) => {
            const { test } = action.payload.entities

            if (test) {
                testAdapter.upsertMany(state, test)
            }
        })
    },
})

export const {
    selectAll: selectAllTests,
    selectById: selectTestById,
    selectIds: selectTestIds,
} = testAdapter.getSelectors<RootState>((state) => state.test)

export default testSlice.reducer
