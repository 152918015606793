import React, { FC } from 'react'
import { IonButton } from '@ionic/react'
import { useAppSelector } from '../../../../hooks'
import { selectInspectionById } from '../../../Inspection/InspectionSlice'

type InspectionOverviewProps = {
    inspectionId: string
    onEdit: () => void
}

const InspectionOverview: FC<InspectionOverviewProps> = ({
    inspectionId,
    onEdit,
}) => {
    const { inspection } = useAppSelector((state) =>
        selectInspectionById(state, +inspectionId)
    )

    return (
        <>
            <p>{inspection}</p>
            <div
                style={{
                    width: '100%',
                    height: '72px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <IonButton
                    fill="clear"
                    onClick={onEdit}
                    style={{
                        paddingTop: '16px',
                        height: '100%',
                    }}
                >
                    Inspektion bearbeiten
                </IonButton>
            </div>
        </>
    )
}

export default InspectionOverview
