import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { ConnectedProps, connect } from 'react-redux'
import {
    IonButton,
    IonButtons,
    IonCol,
    IonGrid,
    IonInput,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonPage,
    IonHeader,
    IonToolbar,
    IonContent,
    IonBackButton,
    IonModal,
    IonDatetime,
    IonCard,
    IonCardContent,
} from '@ionic/react'
import { IonTitle } from '@ionic/react'
import { Formik, FormikProps, FormikValues } from 'formik'
import {
    createPatient,
    fetchPatientById,
    selectPatientById,
    updatePatient,
} from './PatientSlice'
import { RootState } from '../../store'

// const compareWith = (o1: number, o2: number) => {
//     if (!o1 || !o2) {
//         return o1 === o2
//     }

//     if (Array.isArray(o2)) {
//         return o2.some((o) => o.id === o1.id)
//     }

//     return o1.id === o2.id
// }

type PatientEditProps = {}

type PatientEditState = {
    dataLoading: boolean
    birthdayPickerOpen: boolean
}

type RouterProps = RouteComponentProps<{ id: string }>

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PatientEditProps & RouterProps & PropsFromRedux

class PatientEdit extends React.Component<Props, PatientEditState> {
    state: PatientEditState = {
        dataLoading: true,
        birthdayPickerOpen: false,
    }

    componentDidMount() {
        const { fetchPatientById, patientId, mode } = this.props

        if (mode === 'edit') {
            this.setState({ dataLoading: true })
            Promise.all([fetchPatientById({ id: patientId })])
                .then(() => {
                    this.setState({ dataLoading: false })
                })
                .catch(() => {
                    this.setState({ dataLoading: false })
                })
        } else {
            this.setState({ dataLoading: false })
        }
    }

    handleSubmitForm = (values: FormikValues) => {
        const { updatePatient, patientId, createPatient, history } = this.props

        Promise.all([
            patientId
                ? updatePatient({ data: values, id: patientId })
                : createPatient({ data: values }),
        ])
            .then(() => {
                history.push('/patients')
            })
            .catch(() => {
                this.setState({ dataLoading: false })
            })
    }

    renderFieldset = (props: FormikProps<FormikValues>) => {
        const { handleChange, values, handleSubmit, setFieldValue } = props
        const { patientId, mode } = this.props
        const { birthdayPickerOpen } = this.state

        return (
            <>
                <IonGrid justify-content-center>
                    <IonRow>
                        <IonCol size="12" size-sm="6">
                            <IonInput
                                name="firstName"
                                value={values.firstName ? values.firstName : ''}
                                onIonChange={handleChange}
                                fill="outline"
                                label="Vorname"
                                labelPlacement="floating"
                            ></IonInput>
                        </IonCol>
                        <IonCol size="12" size-sm="6">
                            <IonInput
                                name="lastName"
                                value={values.lastName ? values.lastName : ''}
                                onIonChange={handleChange}
                                fill="outline"
                                label="Nachname"
                                labelPlacement="floating"
                            ></IonInput>
                        </IonCol>
                        <IonCol size="12" size-sm="6">
                            <IonSelect
                                onIonChange={(e) => {
                                    setFieldValue(
                                        'gender',
                                        e.detail.value,
                                        false
                                    )
                                }}
                                fill="outline"
                                label="Geschlecht"
                                labelPlacement="floating"
                                name="gender"
                                value={values.gender}
                                interface="action-sheet"
                                style={{ maxWidth: '100% !important' }}
                            >
                                <IonSelectOption key={'m'} value={'m'}>
                                    männlich
                                </IonSelectOption>
                                <IonSelectOption key={'f'} value={'f'}>
                                    weiblich
                                </IonSelectOption>
                                <IonSelectOption key={'d'} value={'d'}>
                                    divers
                                </IonSelectOption>
                            </IonSelect>
                        </IonCol>
                        <IonCol size="12" size-sm="6">
                            <IonInput
                                name="birthday"
                                value={
                                    values.birthday
                                        ? new Date(
                                              values.birthday
                                          ).toLocaleString('de-DE', {
                                              timeZone: 'Europe/Berlin',
                                              day: '2-digit',
                                              month: '2-digit',
                                              year: 'numeric',
                                          })
                                        : ''
                                }
                                fill="outline"
                                label="Geburtstag"
                                labelPlacement="floating"
                                onIonChange={(e) => {
                                    debugger
                                }}
                                onIonFocus={(e) => {
                                    console.log(e)
                                    this.setState({ birthdayPickerOpen: true })
                                }}
                            ></IonInput>
                            <IonModal>
                                <IonDatetime
                                    presentation="date"
                                    preferWheel={true}
                                ></IonDatetime>
                            </IonModal>
                        </IonCol>
                        <IonCol size="12" size-sm="6">
                            <IonInput
                                name="street"
                                fill="outline"
                                labelPlacement="floating"
                                label="Straße"
                                value={values.street ? values.street : ''}
                                onIonChange={handleChange}
                            ></IonInput>
                        </IonCol>
                        <IonCol size="12" size-sm="6">
                            <IonInput
                                type="number"
                                name="houseNumber"
                                fill="outline"
                                labelPlacement="floating"
                                label="Hausnummer"
                                value={
                                    values.houseNumber ? values.houseNumber : ''
                                }
                                onIonChange={handleChange}
                            ></IonInput>
                        </IonCol>
                        <IonCol size="12" size-sm="6">
                            <IonInput
                                type="number"
                                name="zip"
                                fill="outline"
                                labelPlacement="floating"
                                label="PLZ"
                                value={values.zip ? values.zip : ''}
                                onIonChange={handleChange}
                            ></IonInput>
                        </IonCol>
                        <IonCol size="12" size-sm="6">
                            <IonInput
                                name="city"
                                fill="outline"
                                labelPlacement="floating"
                                label="Stadt"
                                value={values.city ? values.city : ''}
                                onIonChange={handleChange}
                            ></IonInput>
                        </IonCol>
                        <IonCol size="12" size-sm="6">
                            <IonInput
                                name="occupation"
                                fill="outline"
                                labelPlacement="floating"
                                label="Beruf"
                                value={
                                    values.occupation ? values.occupation : ''
                                }
                                onIonChange={handleChange}
                            ></IonInput>
                        </IonCol>
                        <IonCol size="12" size-sm="6">
                            <IonInput
                                name="hobbies"
                                fill="outline"
                                labelPlacement="floating"
                                label="Hobbies"
                                value={values.hobbies ? values.hobbies : ''}
                                onIonChange={handleChange}
                            ></IonInput>
                        </IonCol>
                        <IonCol size="12" size-sm="6">
                            <IonInput
                                name="anthropometricData"
                                fill="outline"
                                labelPlacement="floating"
                                label="Anthropometrische Daten"
                                value={
                                    values.anthropometricData
                                        ? values.anthropometricData
                                        : ''
                                }
                                onIonChange={handleChange}
                            ></IonInput>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-align-self-end">
                        <IonButton onClick={() => handleSubmit()}>
                            Speichern
                        </IonButton>
                        {mode === 'edit' && (
                            <IonButton routerLink={'/patients/' + patientId}>
                                Zurück zur Patientenübersicht
                            </IonButton>
                        )}
                    </IonRow>
                </IonGrid>
                <IonModal
                    id="birthday-picker"
                    isOpen={birthdayPickerOpen}
                    onDidDismiss={() => {
                        this.setState({ birthdayPickerOpen: false })
                    }}
                >
                    <IonDatetime
                        showDefaultButtons
                        presentation="date"
                        formatOptions={{
                            date: {
                                day: '2-digit',
                                month: '2-digit',
                                year: 'numeric',
                            },
                        }}
                        doneText="Speichern"
                        cancelText="Abbrechen"
                        onIonChange={(e) => {
                            setFieldValue(
                                'birthday',
                                new Date(e.detail.value as string)
                                    .toISOString()
                                    .split('T')[0]
                            )
                        }}
                    />
                </IonModal>
            </>
        )
    }

    render() {
        const { dataLoading } = this.state
        const { patient, mode } = this.props

        if (dataLoading) {
            return null
        }

        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonBackButton
                                text=""
                                defaultHref={`/patients/`}
                            ></IonBackButton>
                        </IonButtons>
                        <IonTitle>Neuen Patienten anlegen</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonContent fullscreen color="light">
                    <Formik
                        initialValues={
                            mode === 'edit'
                                ? ({ ...patient } as FormikValues)
                                : {}
                        }
                        onSubmit={this.handleSubmitForm}
                    >
                        {(props) => (
                            <form
                                onSubmit={props.handleSubmit}
                                className="full-width"
                            >
                                <IonCard>
                                    <IonCardContent>
                                        {this.renderFieldset(props)}
                                    </IonCardContent>
                                </IonCard>
                            </form>
                        )}
                    </Formik>
                </IonContent>
            </IonPage>
        )
    }
}

const mapState = (state: RootState, ownProps: RouterProps) => {
    const mode = ownProps.match.path.endsWith('/new') ? 'new' : 'edit'
    const id = parseInt(ownProps.match.params.id)

    return {
        mode: mode,
        patientId: id,
        patient: selectPatientById(state, id),
    }
}

const mapDispatch = {
    updatePatient,
    createPatient,
    fetchPatientById,
}

const connector = connect(mapState, mapDispatch)

export default withRouter(connector(PatientEdit))
