import { IonAlert } from '@ionic/react'

type DeleteDialogProps = {
    header: string
    onDelete: () => void
    onDidDismiss: () => void
    isOpen: boolean
}

const DeleteDialog = (props: DeleteDialogProps): JSX.Element => {
    const { header, onDelete, onDidDismiss, isOpen } = props
    return (
        <IonAlert
            isOpen={isOpen}
            onDidDismiss={onDidDismiss}
            header={header}
            buttons={[
                { text: 'Abbrechen', role: 'cancel', handler: onDidDismiss },
                { text: 'Löschen', role: 'delete', handler: onDelete },
            ]}
        />
    )
}

export default DeleteDialog
