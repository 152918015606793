import { combineReducers } from 'redux'
import sessionReducer from './pages/Auth/SessionSlice'
import userReducer from './pages/User/UserSlice'
import toastsReducer from './components/Toasts/toastSlice'
import testReducer from './pages/Test/TestSlice'
import aimReducer from './pages/Aim/AimSlice'
import caseReducer from './pages/Case/CaseSlice'
import documentationReducer from './pages/Documentation/DocumentationSlice'
import flagReducer from './pages/Flag/FlagSlice'
import patientReducer from './pages/Patient/PatientSlice'
import testSelectionReducer from './pages/TestSelection/TestSelectionSlice'
import testEvaluationReducer from './pages/TestEvaluation/TestEvaluationSlice'
import inspectionReducer from './pages/Inspection/InspectionSlice'

const rootReducer = combineReducers({
    session: sessionReducer,
    user: userReducer,
    toasts: toastsReducer,
    test: testReducer,
    aim: aimReducer,
    case: caseReducer,
    documentation: documentationReducer,
    flag: flagReducer,
    patient: patientReducer,
    testSelection: testSelectionReducer,
    testEvaluation: testEvaluationReducer,
    inspection: inspectionReducer,
    //auth
})

export default rootReducer
