import { schema } from 'normalizr'

// GitHub's API may return results with uppercase letters while the query
// doesn't contain any. For example, "someuser" could result in "SomeUser"
// leading to a frozen UI as it wouldn't find "someuser" in the entities.
// That's why we're forcing lower cases down there.

//const Item = new schema.Entity('item', { idAttribute: (item) => item.id });
const User = new schema.Entity('user')
const Course = new schema.Entity('course')
const Patient = new schema.Entity('patient')
const Case = new schema.Entity('case')
const Flag = new schema.Entity('flag')
const CaseIcd = new schema.Entity('caseIcd')
const CaseIcf = new schema.Entity('caseIcf')
const Aim = new schema.Entity('aim')
const Test = new schema.Entity('test')
const TestSelection = new schema.Entity('testSelection')
const Documentation = new schema.Entity('documentation')
const TestDocumentation = new schema.Entity('testDocumentation')
const Session = new schema.Entity('session')
const TestEvaluation = new schema.Entity('testEvaluation')
const Inspection = new schema.Entity('inspection')

const Register = new schema.Entity('register', {
    idAttribute: (register) => register.id,
})

Patient.define({
    idAttribute: (patient) => patient.id,
    courses: [Course],
    cases: [Case],
    user: User,
})

User.define({
    idAttribute: (user) => user.id,
    courses: [Course],
})

Course.define({
    idAttribute: (course) => course.id,
    users: [User],
    patients: [Patient],
})

Case.define({
    idAttribute: (myCase) => myCase.id,
    flags: [Flag],
    icds: [CaseIcd],
    icfs: [CaseIcf],
    aims: [Aim],
    documentations: [Documentation],
    testSelection: TestSelection,
    patient: Patient,
    testDocumentations: [TestDocumentation],
    testEvaluations: [TestEvaluation],
    inspection: Inspection,
})

Flag.define({
    idAttribute: (flag) => flag.id,
    case: Case,
})

CaseIcd.define({
    idAttribute: (caseIcd) => caseIcd.id,
    case: Case,
})

CaseIcf.define({
    idAttribute: (caseIcf) => caseIcf.id,
    case: Case,
})

Aim.define({
    idAttribute: (aim) => aim.id,
    case: Case,
})

Test.define({
    idAttribute: (test) => test.id,
    testSelection: [TestSelection],
})

TestSelection.define({
    idAttribute: (testSelection) => testSelection.id,
    tests: [Test],
})

Inspection.define({
    idAttribute: (inspection) => inspection.id,
    case: Case,
})

Documentation.define({
    idAttribute: (documentation) => documentation.id,
    case: Case,
})

TestEvaluation.define({
    idAttribute: (testEvaluation) => testEvaluation.id,
    case: Case,
    tests: [Test],
})

TestDocumentation.define({
    idAttribute: (testDocumentation) => testDocumentation.id,
    case: Case,
    test: Test,
})

Session.define({
    user: User,
})

// Schemas for Github API responses.
export const Schemas = {
    Register: Register,
    User: User,
    Users: [User],
    Course: Course,
    Courses: [Course],
    Patient: Patient,
    Patients: [Patient],
    Case: Case,
    Cases: [Case],
    Flag: Flag,
    Flags: [Flag],
    CaseIcd: CaseIcd,
    CaseIcds: [CaseIcd],
    CaseIcf: CaseIcf,
    CaseIcfs: [CaseIcf],
    Aim: Aim,
    Aims: [Aim],
    Test: Test,
    Tests: [Test],
    TestSelection: TestSelection,
    TestSelections: [TestSelection],
    Documentation: Documentation,
    Documentations: [Documentation],
    TestDocumentation: TestDocumentation,
    TestDocumentations: [TestDocumentation],
    TestEvaluation: TestEvaluation,
    TestEvaluations: [TestEvaluation],
    Inspection: Inspection,
    Inspections: [Inspection],
    Session: Session,
}
