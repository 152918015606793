import {
    EntityId,
    createAsyncThunk,
    createEntityAdapter,
    createSlice,
} from '@reduxjs/toolkit'
import { TestEvaluation } from './TestEvaluationType'
import { RootState } from '../../store'
import axiosInstance from '../../utils/ApiConfig'
import { normalize, schema } from 'normalizr'
import { Schemas } from '../../schemas'
import { FormikValues } from 'formik'
import { fetchCaseById } from '../Case/CaseSlice'
import { Test } from '../Test/TestType'

const testEvaluationAdapter = createEntityAdapter<TestEvaluation>({
    sortComparer: (a, b) => a.id - b.id,
})

export const fetchAllTestEvaluations = createAsyncThunk(
    'testEvaluation/fetchAll',
    async (params: { patientId: EntityId; caseId: EntityId }) => {
        const { caseId, patientId } = params

        const response = await axiosInstance.get(
            `/patients/${patientId}/cases/${caseId}/testEvaluations/`
        )
        const normalized = normalize<
            schema.Array<TestEvaluation>,
            {
                testEvaluation: Record<string, TestEvaluation>
                test: Record<string, Test>
            }
        >(response.data.data, Schemas.TestEvaluations)

        return { entities: normalized.entities, message: response.data.message }
    }
)

export const fetchTestEvaluationById = createAsyncThunk(
    'testEvaluation/fetchById',
    async (params: { id: EntityId; patientId: EntityId; caseId: EntityId }) => {
        const { id, patientId, caseId } = params

        const response = await axiosInstance.get(
            `/patients/${patientId}/cases/${caseId}/testEvaluations/${id}`
        )
        const normalized = normalize<
            schema.Entity<TestEvaluation>,
            {
                testEvaluation: Record<string, TestEvaluation>
                test: Record<string, Test>
            }
        >(response.data.data, Schemas.TestEvaluation)

        return { entities: normalized.entities, message: response.data.message }
    }
)

export const updateTestEvaluation = createAsyncThunk(
    'testEvaluation/update',
    async (params: {
        id: EntityId
        data: FormikValues
        patientId: EntityId
        caseId: EntityId
    }) => {
        const { id, data, patientId, caseId } = params

        const response = await axiosInstance.put(
            `/patients/${patientId}/cases/${caseId}/testEvaluations/${id}`,
            {
                ...data,
            }
        )
        const normalized = normalize<
            schema.Entity<TestEvaluation>,
            {
                testEvaluation: Record<string, TestEvaluation>
                test: Record<string, Test>
            }
        >(response.data.data, Schemas.TestEvaluation)

        return { entities: normalized.entities, message: response.data.message }
    }
)

export const createTestEvaluation = createAsyncThunk(
    'testEvaluation/create',
    async (params: {
        data: FormikValues
        patientId: EntityId
        caseId: EntityId
    }) => {
        const { data, patientId, caseId } = params

        const response = await axiosInstance.post(
            `/patients/${patientId}/cases/${caseId}/testEvaluations/`,
            {
                ...data,
            }
        )
        // const normalized = normalize<
        //     schema.Entity<TestEvaluation>,
        //     {
        //         testEvaluation: Record<string, TestEvaluation>
        //         test: Record<string, Test>
        //     }
        // >(response.data.data, Schemas.TestEvaluation)

        return { message: response.data.message }
    }
)

export const deleteTestEvaluationById = createAsyncThunk(
    'testEvaluation/delete',
    async (params: { id: EntityId; patientId: EntityId; caseId: EntityId }) => {
        const { id, patientId, caseId } = params

        const response = await axiosInstance.delete(
            `/patients/${patientId}/cases/${caseId}/testEvaluations/${id}`
        )

        return { id: id, message: response.data.message }
    }
)

const testEvaluationSlice = createSlice({
    name: 'testEvaluation',
    initialState: testEvaluationAdapter.getInitialState({}),
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchAllTestEvaluations.fulfilled, (state, action) => {
            const { testEvaluation } = action.payload.entities

            if (testEvaluation) {
                testEvaluationAdapter.upsertMany(state, testEvaluation)
            }
        })
        builder.addCase(fetchTestEvaluationById.fulfilled, (state, action) => {
            const { testEvaluation } = action.payload.entities

            if (testEvaluation) {
                testEvaluationAdapter.upsertMany(state, testEvaluation)
            }
        })
        builder.addCase(deleteTestEvaluationById.fulfilled, (state, action) => {
            const { id } = action.payload

            testEvaluationAdapter.removeOne(state, +id)
        })
        builder.addCase(fetchCaseById.fulfilled, (state, action) => {
            const { testEvaluation } = action.payload.entities

            if (testEvaluation) {
                testEvaluationAdapter.upsertMany(state, testEvaluation)
            }
        })
    },
})

export const {
    selectAll: selectAllTestEvaluations,
    selectById: selectTestEvaluationById,
    selectIds: selectTestEvaluationIds,
} = testEvaluationAdapter.getSelectors<RootState>(
    (state) => state.testEvaluation
)

export default testEvaluationSlice.reducer
