import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonLabel,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar,
    IonIcon,
    IonButtons,
    IonBackButton,
} from '@ionic/react'
import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { ConnectedProps, connect } from 'react-redux'
import { createOutline, addOutline, trashOutline } from 'ionicons/icons'
import moment from 'moment'
import 'moment/locale/de'
import DeleteDialog from '../../components/DeleteDialog/DeleteDialog'
import AddDataButton from '../../components/AddData/AddDataButton'
import {
    deleteCaseById,
    fetchAllCases,
    selectAllCases,
} from '../Case/CaseSlice'
import { fetchPatientById, selectPatientById } from './PatientSlice'
import { RootState } from '../../store'
import { Case } from '../Case/CaseType'
import { makeCasesByPatient } from './selectors'

type PatientDetailProps = {}

type PatientDetailState = {
    dataLoading: boolean
    deleteDialogOpen: boolean
    optionToDelete?: number
}

type RouterProps = RouteComponentProps<{ patientId: string }>

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PatientDetailProps & RouterProps & PropsFromRedux

class PatientDetail extends React.Component<Props, PatientDetailState> {
    state: PatientDetailState = {
        dataLoading: true,
        deleteDialogOpen: false,
    }

    componentDidMount = async () => {
        const { fetchPatientById, fetchAllCases, patientId } = this.props

        this.setState({ dataLoading: true })

        try {
            await fetchPatientById({ id: patientId })
            await fetchAllCases({ patientId: patientId })
            this.setState({ dataLoading: false })
        } catch (err) {
            this.setState({ dataLoading: false })
        }
    }

    handleDelete = (
        e: React.MouseEvent<HTMLIonButtonElement, MouseEvent>,
        id: number
    ) => {
        e.preventDefault()
        e.stopPropagation()
        this.setState({ deleteDialogOpen: true, optionToDelete: id })
    }

    handleDeleteCase = async (id: number) => {
        const { deleteCaseById, patientId } = this.props

        await deleteCaseById({ id, patientId })
        this.setState({ deleteDialogOpen: false, optionToDelete: undefined })
    }

    renderCase = (caseData: Case, index: number) => {
        const { match } = this.props

        return (
            <IonRow key={index}>
                <IonCol>
                    <IonCard
                        button
                        routerLink={`${match.url}/cases/${caseData.id}`}
                    >
                        <IonCardHeader>
                            <IonCardSubtitle
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}
                            >
                                Untersuchungsdatum:{' '}
                                {moment(caseData.reportDate).format('L')} -
                                letzte Behandlung:{' '}
                                {moment(caseData.lastMedication).format('L')}
                                <IonButtons>
                                    <IonButton
                                        fill="clear"
                                        onClick={(e) =>
                                            this.handleDelete(e, caseData.id)
                                        }
                                    >
                                        <IonIcon
                                            color="danger"
                                            slot="icon-only"
                                            style={{ height: '100%' }}
                                            icon={trashOutline}
                                        />
                                    </IonButton>
                                </IonButtons>
                            </IonCardSubtitle>
                            <IonCardTitle>
                                <IonLabel
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <h1>Fall {caseData.id}</h1>
                                </IonLabel>
                            </IonCardTitle>
                        </IonCardHeader>
                        <IonCardContent>
                            <IonGrid>
                                <IonRow>
                                    <IonCol>
                                        {caseData.caseIcfs
                                            ? 'ICFs: ' +
                                              caseData.caseIcfs.join(', ')
                                            : 'keine ICFs ausgewählt'}
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol>
                                        Anamnestische Fallanalyse:{' '}
                                        {caseData.anamnesticCaseAnalysis ?? ''}
                                    </IonCol>
                                    <IonCol>
                                        Beschreibung:{' '}
                                        {caseData.characteristics ??
                                            'nicht vorhanden'}
                                    </IonCol>
                                </IonRow>
                            </IonGrid>
                        </IonCardContent>
                    </IonCard>
                </IonCol>
            </IonRow>
        )
    }

    renderCases = () => {
        const { match, casesByPatient } = this.props

        if (casesByPatient && !isEmpty(casesByPatient)) {
            return <IonGrid>{casesByPatient.map(this.renderCase)}</IonGrid>
        } else {
            return (
                <IonGrid>
                    <IonRow class="ion-justify-content-center ion-align-items-center">
                        <p>Keine Fälle vorhanden</p>
                    </IonRow>

                    <IonRow class="ion-justify-content-center ion-align-items-center">
                        <AddDataButton routerLink={`${match.url}/cases/new`} />
                    </IonRow>
                </IonGrid>
            )
        }
    }

    renderPatientInfos = () => {
        const { patient } = this.props

        if (!patient) {
            return null
        }

        return (
            <>
                <IonGrid>
                    <IonRow>
                        <IonCol style={{ paddingTop: 0, paddingBottom: 0 }}>
                            <p>
                                Name: {patient.firstName} {patient.lastName}
                            </p>
                        </IonCol>
                        <IonCol style={{ paddingTop: 0, paddingBottom: 0 }}>
                            <p>
                                Geschlecht:{' '}
                                {patient.gender === 'm'
                                    ? 'männlich'
                                    : patient.gender === 'f'
                                      ? 'weiblich'
                                      : 'divers'}
                            </p>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol style={{ paddingTop: 0, paddingBottom: 0 }}>
                            <p>Geburtsjahr: {patient.yearOfBirth}</p>
                        </IonCol>
                        <IonCol style={{ paddingTop: 0, paddingBottom: 0 }}>
                            <p>
                                Adresse: {patient.street} {patient.houseNumber},{' '}
                                {patient.zip} {patient.city}
                            </p>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol style={{ paddingTop: 0, paddingBottom: 0 }}>
                            <p>Beruf: {patient.occupation}</p>
                        </IonCol>
                        <IonCol style={{ paddingTop: 0, paddingBottom: 0 }}>
                            <p>Hobbies: {patient.hobbies}</p>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol style={{ paddingTop: 0, paddingBottom: 0 }}>
                            <p>
                                Anthropometrische Daten:{' '}
                                {patient.anthropometricData}
                            </p>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </>
        )
    }

    renderDeleteDialog = () => {
        const { deleteDialogOpen, optionToDelete } = this.state

        if (!optionToDelete) {
            return null
        }

        return (
            <>
                <DeleteDialog
                    header={'Fall ' + optionToDelete + ' wirklich löschen?'}
                    onDelete={() => {
                        this.handleDeleteCase(optionToDelete)
                    }}
                    onDidDismiss={() =>
                        this.setState({ deleteDialogOpen: false })
                    }
                    isOpen={deleteDialogOpen}
                />
            </>
        )
    }

    render() {
        const { dataLoading } = this.state
        const { patient, match } = this.props

        if (dataLoading || isEmpty(patient)) {
            return null
        }

        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar mode="ios">
                        <IonButtons slot="start">
                            <IonBackButton
                                text=""
                                defaultHref={`/patients/`}
                            ></IonBackButton>
                        </IonButtons>
                        <IonTitle>
                            Patient: {patient.lastName}, {patient.firstName}
                        </IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent color="light">
                    <IonCard>
                        <IonCardHeader>
                            {/* <IonCardTitle>Patientendaten</IonCardTitle> */}
                            <IonCardTitle>
                                <span
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <IonLabel>
                                        <h1>Patientendaten</h1>
                                    </IonLabel>
                                    <IonButtons>
                                        <IonButton
                                            fill="clear"
                                            routerLink={
                                                '/patients/edit/' + patient.id
                                            }
                                        >
                                            <IonIcon
                                                slot="icon-only"
                                                //style={{ height: '100%' }}
                                                icon={createOutline}
                                            />
                                        </IonButton>
                                    </IonButtons>
                                </span>
                            </IonCardTitle>
                        </IonCardHeader>
                        <IonCardContent>
                            {this.renderPatientInfos()}
                        </IonCardContent>
                    </IonCard>
                    <IonToolbar color="light">
                        <IonTitle>
                            <h1>Fallübersicht</h1>
                        </IonTitle>
                        <IonButtons slot="end">
                            <IonButton
                                routerLink={`${match.url}/cases/new`}
                                fill="clear"
                            >
                                <IonIcon slot="icon-only" icon={addOutline} />
                                {/* Neuen Fall Anlegen */}
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                    {/* {this.renderDummyData()} */}
                    {this.renderCases()}
                    {this.renderDeleteDialog()}
                </IonContent>
            </IonPage>
        )
    }
}

const makeMapState = () => {
    const getCasesByPatient = makeCasesByPatient()

    return (state: RootState, ownProps: RouterProps) => {
        const patientId = parseInt(ownProps.match?.params?.patientId)
        const casesByPatient = getCasesByPatient(state, patientId)

        return {
            patientId: patientId,
            patient: selectPatientById(state, patientId),
            cases: selectAllCases(state),
            match: ownProps.match,
            casesByPatient: casesByPatient,
        }
    }
}

const mapDispatch = {
    fetchPatientById,
    deleteCaseById,
    fetchAllCases,
}

const connector = connect(makeMapState, mapDispatch)

export default withRouter(connector(PatientDetail))
