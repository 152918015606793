import React from 'react'

import ACL from '../../components/ACL'
import Documentation from './Documentation'
import RoleEnum from '../../utils/Enum/RoleEnum'

const DocumentationIndex = (): JSX.Element => {
    return (
        <ACL
            allowedRoles={[
                RoleEnum.ROLE_USER,
                RoleEnum.ROLE_MOD,
                RoleEnum.ROLE_ADMIN,
            ]}
        >
            <Documentation />
        </ACL>
    )
}

export default DocumentationIndex
