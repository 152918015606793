import { IonPage, IonRouterOutlet } from '@ionic/react'
import { FC, useEffect } from 'react'
import { Route, useParams, useRouteMatch } from 'react-router-dom'
import TestEvaluationEdit from './TestEvaluationEdit'
import { useAppDispatch } from '../../hooks'
import { fetchCaseById } from '../Case/CaseSlice'

const TestEvaluation: FC = () => {
    const { path } = useRouteMatch()
    const { caseId, patientId } = useParams<{
        caseId: string
        patientId: string
    }>()
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(fetchCaseById({ id: caseId, patientId }))
    }, [dispatch, caseId, patientId])

    return (
        <IonPage>
            <IonRouterOutlet>
                <Route
                    exact
                    path={`${path}/:testEvaluationId`}
                    component={TestEvaluationEdit}
                />
            </IonRouterOutlet>
        </IonPage>
    )
}

export default TestEvaluation
