import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { ConnectedProps, connect } from 'react-redux'
import {
    IonIcon,
    IonItem,
    IonText,
    IonButton,
    IonList,
    IonChip,
} from '@ionic/react'
import { removeCircleOutline } from 'ionicons/icons'
import { isEmpty } from 'lodash'
import { deleteAimById } from '../../Aim/AimSlice'
import MyAccordion from '../../../components/MyAccordion'
import { makeSelectAimsByCase } from '../../Aim/selectors'
import DeleteDialog from '../../../components/DeleteDialog/DeleteDialog'
import AddDataButton from '../../../components/AddData/AddDataButton'
import { fetchCaseById, selectCaseById } from '../CaseSlice'
import { RootState } from '../../../store'
import { Aim } from '../../Aim/AimType'
import AimTypeEnum from '../../../utils/Enum/AimTypeEnum'

type AimsProps = {
    disabled: boolean
}

type AimsState = {
    deleteDialogOpen: boolean
    optionToDelete: number | null
}

type RouterProps = RouteComponentProps<{
    caseId: string
    aimId: string
    patientId: string
}>

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = AimsProps & RouterProps & PropsFromRedux

class Aims extends React.Component<Props, AimsState> {
    state = {
        deleteDialogOpen: false,
        optionToDelete: null,
    }

    componentDidMount() {}

    deleteAim = (id: number) => {
        this.setState({ deleteDialogOpen: true, optionToDelete: id })
    }

    handleDeleteAim = async (id: number) => {
        const { deleteAimById, caseId, patientId, fetchCaseById } = this.props

        await deleteAimById({ id, caseId, patientId })
        await fetchCaseById({ id: caseId, patientId })
        this.setState({ deleteDialogOpen: false, optionToDelete: null })
    }

    renderAddData = () => {
        const { match, disabled } = this.props

        return (
            <div className="index-main">
                <p style={{ paddingTop: '16px' }}>Neues Ziel hinzufügen</p>
                <AddDataButton
                    disabled={disabled}
                    routerLink={`${match.url}/aims/new`}
                />
            </div>
        )
    }

    renderChip = (type: AimTypeEnum) => {
        if (type === AimTypeEnum.TYPE_THERAPEUT) {
            return <IonChip color="secondary">Therapeutenziel</IonChip>
        } else {
            return <IonChip color="tertiary">Patientenziel</IonChip>
        }
    }

    renderAim = (aim: Aim, index: number) => {
        const { history, match } = this.props
        const id = aim.id

        return (
            <IonItem
                key={index}
                button
                onClick={(e) => {
                    e.preventDefault()
                    history.push(`${match.url}/aims/${id}`)
                }}
            >
                {this.renderChip(aim.type)}
                <IonText>{aim.description}</IonText>
                <IonButton
                    onClick={(e) => {
                        e.stopPropagation()
                        this.deleteAim(id)
                    }}
                    shape="round"
                    fill="clear"
                    slot="end"
                >
                    <IonIcon
                        color="danger"
                        slot="icon-only"
                        icon={removeCircleOutline}
                    />
                </IonButton>
            </IonItem>
        )
    }

    renderAims = () => {
        const { aimsByCase } = this.props

        if (isEmpty(aimsByCase)) {
            return null
        }

        return (
            <>
                <IonList>
                    {aimsByCase.map((aim, index) => this.renderAim(aim, index))}
                </IonList>
                {this.renderAddData()}
                {this.renderDeleteDialog()}
            </>
        )
    }

    renderDeleteDialog = () => {
        const { deleteDialogOpen, optionToDelete } = this.state

        if (!optionToDelete) {
            return null
        }

        return (
            <>
                <DeleteDialog
                    header={'Ziel wirklich löschen?'}
                    onDelete={() => {
                        this.handleDeleteAim(optionToDelete)
                    }}
                    onDidDismiss={() =>
                        this.setState({ deleteDialogOpen: false })
                    }
                    isOpen={deleteDialogOpen}
                />
            </>
        )
    }

    render() {
        const { caseById } = this.props

        const dataAvailable =
            !!caseById &&
            caseById.hasOwnProperty('aims') &&
            !isEmpty(caseById['aims'])

        return (
            <>
                <MyAccordion
                    title={`Ziele (${
                        dataAvailable && !isEmpty(caseById.aims)
                            ? Object.keys(caseById?.aims).length
                            : '0'
                    })`}
                >
                    {dataAvailable ? this.renderAims() : this.renderAddData()}
                </MyAccordion>
                {/*this.renderModal()*/}
            </>
        )
    }
}

const makeMapState = () => {
    const selectAimsByCase = makeSelectAimsByCase()

    return (state: RootState, ownProps: RouterProps) => {
        const caseId = parseInt(ownProps.match.params.caseId)
        const patientId = parseInt(ownProps.match.params.patientId)
        const match = ownProps.match
        const aimsByCase = selectAimsByCase(state, caseId)
        const caseById = selectCaseById(state, caseId)

        return {
            caseById: caseById,
            match: match,
            aimsByCase: aimsByCase,
            caseId: caseId,
            isNewCase: match.params.caseId === 'new',
            patientId,
        }
    }
}

const mapDispatch = {
    deleteAimById,
    fetchCaseById,
}

const connector = connect(makeMapState, mapDispatch)

export default withRouter(connector(Aims))
