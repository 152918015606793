import React, { FC } from 'react'
import './Patient.css'
import { Route } from 'react-router-dom'
import PatientList from './PatientList'
import PatientDetail from './PatientDetail'
import { IonRouterOutlet } from '@ionic/react'
import { IonPage } from '@ionic/react'
import PatientEdit from './PatientEdit'
import CaseIndex from '../Case/CaseIndex'

const Patient: FC = () => {
    return (
        <IonPage>
            <IonRouterOutlet>
                <Route exact path={'/patients'} component={PatientList} />
                <Route
                    exact
                    path={`/patients/:patientId`}
                    component={PatientDetail}
                />
                <Route path={`/patients/new`} component={PatientEdit} />
                <Route path={`/patients/edit/:id`} component={PatientEdit} />
                <Route
                    path={`/patients/:patientId/cases`}
                    component={CaseIndex}
                />
            </IonRouterOutlet>
        </IonPage>
    )
}

export default Patient
