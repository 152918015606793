import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { ConnectedProps, connect } from 'react-redux'
import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonCheckbox,
    IonChip,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonModal,
    IonPage,
    IonRange,
    IonRow,
    IonSearchbar,
    IonSelect,
    IonSelectOption,
    IonTextarea,
    IonTitle,
    IonToolbar,
} from '@ionic/react'
import { Formik, FormikProps, FormikValues } from 'formik'
import { isEmpty } from 'lodash'
import { searchIcf } from '../../utils/IcfIcdHandler'
import { Virtuoso } from 'react-virtuoso'
import { addOutline, close } from 'ionicons/icons'
import {
    fetchTestById,
    selectTestById,
    updateTest,
    createTest,
} from './TestSlice'
import { RootState } from '../../store'
import { IcfIcdEntry } from '../../utils/IcfIcdType'
import { SearchbarCustomEvent } from '@ionic/core'

type TestEditProps = {}

type TestEditState = {
    openModal: boolean
    searchInitialized: boolean
    dataLoading: boolean
    searchResults: {
        chapter: IcfIcdEntry[]
        block: IcfIcdEntry[]
        fourdigit: IcfIcdEntry[]
        fivedigit: IcfIcdEntry[]
        sixdigit: IcfIcdEntry[]
        items: IcfIcdEntry[]
        itemCount: number
        separationIndicies: number[]
    } | null
}

type RouterProps = RouteComponentProps<{ testId: string }>

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = TestEditProps & RouterProps & PropsFromRedux

class TestEdit extends React.Component<Props, TestEditState> {
    state = {
        openModal: false,
        searchInitialized: false,
        dataLoading: true,
        searchResults: {
            chapter: [],
            block: [],
            fourdigit: [],
            fivedigit: [],
            sixdigit: [],
            items: [],
            itemCount: 0,
            separationIndicies: [],
        },
    }

    componentDidMount() {
        const { mode, fetchTestById, testId } = this.props

        this.initializeSearch()
        if (mode === 'edit') {
            this.setState({ dataLoading: true })
            Promise.all([fetchTestById({ id: testId })]).then(() =>
                this.setState({ dataLoading: false })
            )
        } else {
            this.setState({ dataLoading: false })
        }
    }

    initializeSearch = () => {
        let filter = searchIcf('')
        this.setState({ searchResults: filter, searchInitialized: true })
    }

    handleSubmitForm = (values: FormikValues) => {
        const { createTest, history, testId, mode, updateTest } = this.props

        const promises =
            mode === 'new'
                ? [createTest({ data: values })]
                : [updateTest({ data: values, id: testId })]

        Promise.all(promises).then((val) => {
            history.push('/tests')
        })
    }

    renderFieldset = (props: FormikProps<FormikValues>) => {
        const { handleChange, values, handleSubmit, setFieldValue } = props

        return (
            <>
                <IonGrid>
                    <IonRow>
                        <IonCol size="12" size-sm="6">
                            <IonInput
                                name="name"
                                value={values['name'] ?? ''}
                                onIonChange={handleChange}
                                maxlength={80}
                                type="text"
                                fill="outline"
                                labelPlacement="floating"
                                label="Name"
                                counter
                            />
                        </IonCol>
                        <IonCol size="12" size-sm="6">
                            <IonInput
                                name="abbreviation"
                                value={values['abbreviation'] ?? ''}
                                onIonChange={handleChange}
                                maxlength={20}
                                type="text"
                                fill="outline"
                                labelPlacement="floating"
                                label="Abkürzung"
                                counter
                            />
                        </IonCol>
                        <IonCol size="12">
                            <IonTextarea
                                name="shortDescription"
                                value={values['shortDescription'] ?? ''}
                                onIonChange={handleChange}
                                placeholder="Beschreibung"
                                labelPlacement="floating"
                                fill="outline"
                                autoGrow
                            />
                        </IonCol>
                        <IonCol size="12" size-sm="6">
                            <IonSelect
                                placeholder="Bitte auswählen"
                                label="Assesment Klassifikation"
                                labelPlacement="floating"
                                name="assessmentClassification"
                                fill="outline"
                                onIonChange={(e) => {
                                    setFieldValue(
                                        'assessmentClassification',
                                        e.detail.value,
                                        false
                                    )
                                }}
                                value={values.assessmentClassification}
                            >
                                <IonSelectOption value="aktiv">
                                    Aktiver Test
                                </IonSelectOption>
                                <IonSelectOption value="passiv">
                                    Passiver Test
                                </IonSelectOption>
                                <IonSelectOption value="fragebogen">
                                    Fragebogen
                                </IonSelectOption>
                            </IonSelect>
                        </IonCol>
                        <IonCol size="12" size-sm="6">
                            <IonSelect
                                placeholder="Bitte auswählen"
                                label="Fachgebiet"
                                labelPlacement="floating"
                                fill="outline"
                                name="specialistArea"
                                onIonChange={(e) => {
                                    setFieldValue(
                                        'specialistArea',
                                        e.detail.value,
                                        false
                                    )
                                }}
                                value={values.specialistArea}
                            >
                                <IonSelectOption value="Allgemein">
                                    Allgemein
                                </IonSelectOption>
                                <IonSelectOption value="Chirurgie">
                                    Chirurgie
                                </IonSelectOption>
                                <IonSelectOption value="Orthopädie">
                                    Orthopädie
                                </IonSelectOption>
                                <IonSelectOption value="Geriatrie">
                                    Geriatrie
                                </IonSelectOption>
                                <IonSelectOption value="Innere Medizin">
                                    Innere Medizin
                                </IonSelectOption>
                                <IonSelectOption value="Gynäkologie">
                                    Gynäkologie
                                </IonSelectOption>
                                <IonSelectOption value="Pädiatrie">
                                    Pädiatrie
                                </IonSelectOption>
                                <IonSelectOption value="Neurologie">
                                    Neurologie
                                </IonSelectOption>
                                <IonSelectOption value="Sportmedizin">
                                    Sportmedizin
                                </IonSelectOption>
                            </IonSelect>
                        </IonCol>
                        <IonCol size="12" size-sm="6">
                            <IonInput
                                name="tags"
                                value={values['tags'] ?? ''}
                                onIonChange={handleChange}
                                type="text"
                                fill="outline"
                                labelPlacement="floating"
                                label="Tags"
                                placeholder="tag1, tag2, tag3"
                                counter
                            />
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="12" size-sm="6">
                            <IonRange
                                name="specificity"
                                onIonChange={handleChange}
                                max={100}
                                min={1}
                                value={values['specificity'] ?? 0}
                                label="Spezifität"
                                labelPlacement="fixed"
                                ticks
                                snaps
                                pin
                            ></IonRange>
                        </IonCol>
                        <IonCol size="12" size-sm="6">
                            <IonRange
                                name="sensitivity"
                                onIonChange={handleChange}
                                max={100}
                                min={1}
                                value={values['sensitivity'] ?? 0}
                                label="Sensitivität"
                                labelPlacement="fixed"
                                ticks
                                snaps
                                pin
                            ></IonRange>
                        </IonCol>
                        <IonCol size="12" size-sm="6">
                            <IonInput
                                name="durationOfEvaluation"
                                value={values['durationOfEvaluation'] ?? ''}
                                onIonChange={handleChange}
                                type="text"
                                fill="outline"
                                labelPlacement="floating"
                                label="Auswertungsdauer in Sekunden"
                                placeholder="Auswertungsdauer in Sekunden"
                                counter
                            />
                        </IonCol>
                        <IonCol size="12" size-sm="6">
                            <IonInput
                                name="durationOfExecution"
                                value={values['durationOfExecution'] ?? ''}
                                onIonChange={handleChange}
                                type="text"
                                fill="outline"
                                labelPlacement="floating"
                                label="Ausführungsdauer in Sekunden"
                                placeholder="Ausführungsdauer in Sekunden"
                                counter
                            />
                        </IonCol>
                        <IonCol size="12" size-sm="6">
                            <IonInput
                                name="url"
                                value={values['url'] ?? ''}
                                onIonChange={handleChange}
                                type="url"
                                fill="outline"
                                labelPlacement="floating"
                                label="URL"
                                placeholder="URL"
                                counter
                            />
                        </IonCol>
                        <IonCol size="12">
                            <IonList>
                                <IonListHeader>
                                    <IonLabel
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        ICFs
                                        <IonButton
                                            onClick={() =>
                                                this.setState({
                                                    openModal: true,
                                                })
                                            }
                                            shape="round"
                                        >
                                            <IonIcon
                                                slot="icon-only"
                                                icon={addOutline}
                                            />
                                        </IonButton>
                                    </IonLabel>
                                </IonListHeader>
                                {values.testIcfs &&
                                    !isEmpty(values.testIcfs) &&
                                    values.testIcfs.map(
                                        (
                                            icf: string,
                                            index: number,
                                            array: string[]
                                        ) => {
                                            return (
                                                <IonChip key={index}>
                                                    <IonLabel>{icf}</IonLabel>
                                                    <IonIcon
                                                        icon={close}
                                                        onClick={() => {
                                                            const index =
                                                                array.indexOf(
                                                                    icf
                                                                )
                                                            array.splice(
                                                                index,
                                                                1
                                                            )
                                                            props.setFieldValue(
                                                                'testIcfs',
                                                                array
                                                            )
                                                        }}
                                                    ></IonIcon>
                                                </IonChip>
                                            )
                                        }
                                    )}{' '}
                            </IonList>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <IonButton onClick={() => handleSubmit()}>Speichern</IonButton>
            </>
        )
    }

    handleItemClick = (icf: IcfIcdEntry, props: FormikProps<FormikValues>) => {
        const { values, setFieldValue } = props

        const index = values['testIcfs'].indexOf(icf.code)

        if (index > -1) {
            values['testIcfs'].splice(index, 1)
        } else {
            values['testIcfs'].push(icf.code)
        }

        setFieldValue('testIcfs', values['testIcfs'], false)
    }

    renderIcf = (
        icf: IcfIcdEntry,
        index: number,
        showCheckbox = true,
        props: FormikProps<FormikValues>
    ) => {
        if (!showCheckbox) {
            return (
                <IonItem key={index}>
                    <IonLabel>
                        {' '}
                        {icf.code} {' - '}
                        {icf.label}
                    </IonLabel>
                </IonItem>
            )
        }

        const { values } = props

        return (
            <IonItem
                key={index}
                onClick={() => this.handleItemClick(icf, props)}
            >
                <IonLabel>
                    {' '}
                    {icf.code} {' - '}
                    {icf.label}
                </IonLabel>
                <IonCheckbox
                    checked={
                        !isEmpty(values['testIcfs']) &&
                        values['testIcfs'].includes(icf.code)
                    }
                    slot="end"
                ></IonCheckbox>
            </IonItem>
        )
    }

    renderSearchResults = (props: FormikProps<FormikValues>) => {
        const { searchResults } = this.state

        if (!searchResults) {
            return null
        }

        const {
            searchResults: { items, itemCount, separationIndicies },
        } = this.state

        return (
            <IonList style={{ height: 'calc(100% - 102px)', paddingBottom: 0 }}>
                <Virtuoso
                    className="ion-content-scroll-host"
                    //style={{ height: '100%' }}
                    totalCount={itemCount}
                    itemContent={(index) => (
                        <div>
                            {index === separationIndicies[0] && (
                                <IonListHeader>Kapitel</IonListHeader>
                            )}
                            {index === separationIndicies[1] && (
                                <IonListHeader>Gruppe</IonListHeader>
                            )}
                            {index === separationIndicies[2] && (
                                <IonListHeader>Kategorie</IonListHeader>
                            )}
                            {index === separationIndicies[3] && (
                                <IonListHeader>Subkategorie 1</IonListHeader>
                            )}
                            {index === separationIndicies[4] && (
                                <IonListHeader>Subkategorie 2</IonListHeader>
                            )}
                            {this.renderIcf(items[index], index, true, props)}
                        </div>
                    )}
                />
            </IonList>
        )
    }

    handleSearchChange = (e: SearchbarCustomEvent) => {
        let filter = searchIcf(e.detail.value)
        this.setState({ searchResults: filter })
    }

    renderModal = (props: FormikProps<FormikValues>) => {
        const { openModal, searchInitialized } = this.state

        if (!searchInitialized) {
            return null
        }

        return (
            <IonModal
                isOpen={openModal}
                onDidDismiss={() => {
                    this.setState({ openModal: false })
                }}
                className="fullscreen"
            >
                <IonHeader mode="ios">
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonButton
                                color="medium"
                                onClick={() =>
                                    this.setState({ openModal: false })
                                }
                            >
                                Abbrechen
                            </IonButton>
                        </IonButtons>
                        <IonTitle>ICF</IonTitle>
                        <IonButtons slot="end">
                            <IonButton
                                onClick={() => {
                                    this.setState({ openModal: false })
                                }}
                            >
                                Speichern
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent scrollY={false}>
                    <IonSearchbar
                        onIonInput={this.handleSearchChange}
                        autocapitalize="off"
                    />
                    {this.renderSearchResults(props)}
                </IonContent>
            </IonModal>
        )
    }

    render() {
        const { testById, mode } = this.props
        const { dataLoading } = this.state

        if (dataLoading || (mode === 'edit' && isEmpty(testById))) {
            return null
        }

        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonBackButton
                                text=""
                                defaultHref={`/tests/`}
                            ></IonBackButton>
                        </IonButtons>
                        <IonTitle>Neuen Test anlegen</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent fullscreen>
                    <Formik
                        initialValues={
                            mode === 'new'
                                ? ({ testIcfs: [] } as FormikValues)
                                : { ...testById }
                        }
                        onSubmit={this.handleSubmitForm}
                    >
                        {(props) => (
                            <>
                                <form
                                    onSubmit={props.handleSubmit}
                                    className="full-width"
                                >
                                    {this.renderFieldset(props)}
                                </form>
                                {this.renderModal(props)}
                            </>
                        )}
                    </Formik>
                </IonContent>
            </IonPage>
        )
    }
}
const mapState = () => {
    return (state: RootState, ownProps: RouterProps) => {
        let mode = ownProps.match?.path
        if (mode !== undefined) {
            mode = mode.endsWith('/new') ? 'new' : 'edit'
        }
        const id = parseInt(ownProps.match?.params?.testId)

        const testById = selectTestById(state, id)

        return {
            mode: mode,
            testId: id,
            testById: testById,
        }
    }
}

const mapDispatch = { createTest, fetchTestById, updateTest }

const connector = connect(mapState, mapDispatch)

export default withRouter(connector(TestEdit))
