enum IcfIcdModeEnum {
    MODE_ICF = 'icf',
    MODE_ICD = 'icd',
}

enum IcfFilterEnum {
    CHAPTER = 'chapter',
    BLOCK = 'block',
    FOURDIGIT = 'fourdigit',
    FIVEDIGIT = 'fivedigit',
    SIXDIGIT = 'sixdigit',
}

enum IcdFilterEnum {
    CATEGORY = 'category',
}

export { IcfIcdModeEnum, IcfFilterEnum, IcdFilterEnum }
