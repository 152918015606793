import React, { FC, useEffect } from 'react'
import { IonPage, IonRouterOutlet } from '@ionic/react'
import {
    Route,
    RouteComponentProps,
    useParams,
    useRouteMatch,
} from 'react-router-dom'
import CaseOverview from '../CaseOverview'
import DocumentationIndex from '../../Documentation/DocumentationIndex'
import InspectionRoutes from './Inspection/InspectionRoutes'
import AnamnesticCaseAnalysis from '../../AnamnesticCaseAnalysis/AnamnesticCaseAnalysis'
import AimIndex from '../../Aim/AimIndex'
import TestEvaluationIndex from '../../TestEvaluation/TestEvaluationIndex'
import { useAppDispatch } from '../../../hooks'
import { fetchCaseById } from '../CaseSlice'
import { isEqual } from 'lodash'

const CaseLoader: FC<RouteComponentProps> = () => {
    const { path } = useRouteMatch()

    const { caseId, patientId } = useParams<{
        caseId: string
        patientId: string
    }>()
    const dispatch = useAppDispatch()
    const isNewCase = isEqual(caseId, 'new')

    useEffect(() => {
        if (!isNewCase) {
            dispatch(fetchCaseById({ id: caseId, patientId }))
        }
    }, [caseId, dispatch, isNewCase, patientId])

    return (
        <IonPage>
            <IonRouterOutlet>
                <Route exact path={`${path}/`} component={CaseOverview} />
                <Route
                    path={`${path}/documentations`}
                    component={DocumentationIndex}
                />
                <Route
                    path={`${path}/inspection`}
                    component={InspectionRoutes}
                />
                <Route
                    path={`${path}/anamnesticcaseanalysis`}
                    component={AnamnesticCaseAnalysis}
                />
                <Route path={`${path}/aims`} component={AimIndex} />
                <Route
                    path={`${path}/testevaluations`}
                    component={TestEvaluationIndex}
                />
            </IonRouterOutlet>
        </IonPage>
    )
}

export default CaseLoader
