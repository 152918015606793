import React from 'react'
import ACL from '../../components/ACL'
import User from './User'
import RoleEnum from '../../utils/Enum/RoleEnum'

const UserIndex = () => (
    <ACL
        allowedRoles={[
            RoleEnum.ROLE_USER,
            RoleEnum.ROLE_MOD,
            RoleEnum.ROLE_ADMIN,
        ]}
    >
        <User />
    </ACL>
)

export default UserIndex
