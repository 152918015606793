import React, { FC, useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
    IonBackButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonContent,
    IonHeader,
    IonPage,
    IonSegment,
    IonSegmentButton,
    IonTitle,
    IonToolbar,
    SegmentValue,
} from '@ionic/react'
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import InspectionForm from './InspectionForm'
import MedDrawer from '../../../Documentation/MedDrawer'
import {
    createInspection,
    selectInspectionById,
    updateInspection,
} from '../../../Inspection/InspectionSlice'
import { Form, Formik, FormikValues } from 'formik'
import { useHistory } from 'react-router'
import { Inspection } from '../../../Inspection/InspectionType'
import { selectCaseById } from '../../CaseSlice'

const InspectionEdit: FC = () => {
    const { caseId, patientId } = useParams<{
        caseId: string
        patientId: string
    }>()
    const caseById = useAppSelector((state) =>
        selectCaseById(state, parseInt(caseId))
    )
    const inspectionId = caseById?.inspection
    const [segmentValue, setSegmentValue] =
        useState<SegmentValue>('documentation')
    const isNew = !Boolean(inspectionId)
    const dispatch = useAppDispatch()
    const history = useHistory()
    const inspection = useAppSelector((state) =>
        selectInspectionById(state, parseInt(inspectionId))
    )

    const handleSubmitUpdateForm = useCallback(
        async (values: FormikValues) => {
            const response = await dispatch(
                updateInspection({
                    id: inspectionId,
                    data: values,
                    caseId,
                    patientId,
                })
            ).unwrap()

            if (response.statusCode === 200) {
                history.push(`/patients/${patientId}/cases/${caseId}`)
            }
        },
        [caseId, dispatch, history, inspectionId, patientId]
    )

    const handleSubmitCreateForm = useCallback(
        async (values: FormikValues) => {
            const response = await dispatch(
                createInspection({
                    data: values,
                    caseId,
                    patientId,
                })
            ).unwrap()

            if (response.statusCode === 201) {
                history.push(`/patients/${patientId}/cases/${caseId}`)
            }
        },
        [caseId, dispatch, history, patientId]
    )

    if (!inspection && !isNew) {
        return null
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar mode="ios">
                    <IonButtons slot="start">
                        <IonBackButton
                            text=""
                            defaultHref={`/patients/${patientId}/cases/${caseId}`}
                        ></IonBackButton>
                    </IonButtons>
                    <IonTitle>
                        {inspection
                            ? 'Inspektion bearbeiten'
                            : 'Neue Inspektion'}
                    </IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent color="light">
                <Formik
                    initialValues={(isNew ? {} : inspection) as Inspection}
                    onSubmit={
                        isNew ? handleSubmitCreateForm : handleSubmitUpdateForm
                    }
                >
                    <Form>
                        <IonSegment
                            value={segmentValue}
                            style={{
                                width: '90%',
                                margin: '0 auto',
                                marginTop: '8px',
                                marginBottom: '8px',
                            }}
                            onIonChange={(e) =>
                                setSegmentValue(e.detail.value!)
                            }
                        >
                            <IonSegmentButton value="documentation">
                                Dokumentation
                            </IonSegmentButton>
                            <IonSegmentButton value="drawing">
                                Zeichenmodus
                            </IonSegmentButton>
                        </IonSegment>
                        <IonCard>
                            <IonCardContent>
                                {segmentValue === 'documentation' ? (
                                    <InspectionForm />
                                ) : (
                                    <MedDrawer />
                                )}
                            </IonCardContent>
                        </IonCard>
                    </Form>
                </Formik>
            </IonContent>
        </IonPage>
    )
}

export default InspectionEdit
