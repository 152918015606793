import React, { FC, memo } from 'react'
import AddDataButton from './AddDataButton'

type AddDataProps = {
    disabled: boolean
    onClick: () => void
    title: string
}

const AddData: FC<AddDataProps> = ({ disabled, onClick, title }) => {
    return (
        <div className="index-main">
            <p style={{ paddingTop: '16px' }}>{title}</p>
            <AddDataButton disabled={disabled} onClick={onClick} />
        </div>
    )
}

export default memo(AddData)
