import {
    createAsyncThunk,
    createEntityAdapter,
    createSlice,
} from '@reduxjs/toolkit'
import { User } from './UserType'
import axiosInstance from '../../utils/ApiConfig'
import { normalize, schema } from 'normalizr'
import { Schemas } from '../../schemas'
import { RootState } from '../../store'
import { loginUser, logoutUser } from '../Auth/SessionSlice'
import { FormikValues } from 'formik'

const userAdapter = createEntityAdapter<User>({
    sortComparer: (a, b) => a.id - b.id,
})

export const fetchAllUsers = createAsyncThunk('user/fetchAll', async () => {
    const response = await axiosInstance.get('/users/')
    const normalized = normalize<
        schema.Array<User>,
        {
            user: Record<string, User>
        }
    >(response.data.data, Schemas.Users)

    return { entities: normalized.entities }
})

export const fetchUserById = createAsyncThunk(
    'user/fetchById',
    async (params: { id: number }) => {
        const { id } = params

        const response = await axiosInstance.get(`/users/${id}`)
        const normalized = normalize<
            schema.Entity<User>,
            {
                user: Record<string, User>
            }
        >(response.data.data, Schemas.User)

        return { entities: normalized.entities }
    }
)

export const createUser = createAsyncThunk(
    'user/create',
    async (params: { data: FormikValues }) => {
        const { data } = params

        const response = await axiosInstance.post('/users/', data)
        const normalized = normalize<
            schema.Entity<User>,
            {
                user: Record<string, User>
            }
        >(response.data.data, Schemas.User)

        return { statusCode: response.status, entities: normalized.entities }
    }
)

export const updateUser = createAsyncThunk(
    'user/update',
    async (params: { id: number | string; data: FormikValues }) => {
        const { id, data } = params
        const response = await axiosInstance.put(`/users/${id}`, {
            ...data,
        })
        const normalized = normalize<
            schema.Entity<User>,
            {
                user: Record<string, User>
            }
        >(response.data.data, Schemas.User)

        return { entities: normalized.entities }
    }
)

const userSlice = createSlice({
    name: 'user',
    initialState: userAdapter.getInitialState({
        loading: false,
    }),
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchAllUsers.fulfilled, (state, action) => {
            const { user } = action.payload.entities

            if (user) {
                userAdapter.upsertMany(state, user)
            }
        })
        builder.addCase(createUser.fulfilled, (state, action) => {
            const { user } = action.payload.entities

            if (user) {
                userAdapter.upsertMany(state, user)
            }
        })
        builder.addCase(fetchUserById.fulfilled, (state, action) => {
            const { user } = action.payload.entities

            if (user) {
                userAdapter.upsertMany(state, user)
            }
        })
        builder.addCase(loginUser.fulfilled, (state, action) => {
            const { user } = action.payload

            if (user) {
                userAdapter.addOne(state, user)
            }
        })
        builder.addCase(logoutUser.fulfilled, (state, action) => {
            userAdapter.removeAll(state)
        })
    },
})

export const {
    selectAll: selectAllUsers,
    selectById: selectUserById,
    selectIds: selectUserIds,
} = userAdapter.getSelectors<RootState>((state) => state.user)

export default userSlice.reducer
