import {
    createAsyncThunk,
    createEntityAdapter,
    createSlice,
    EntityId,
} from '@reduxjs/toolkit'
import { TestSelection } from './TestSelectionType'
import { RootState } from '../../store'
import axiosInstance from '../../utils/ApiConfig'
import { normalize, schema } from 'normalizr'
import { Schemas } from '../../schemas'
import { FormikValues } from 'formik'
import { fetchCaseById } from '../Case/CaseSlice'
import { Test } from '../Test/TestType'

const testSelectionAdapter = createEntityAdapter<TestSelection>({
    sortComparer: (a, b) => a.id - b.id,
})

/*export const fetchAllTestSelections = createAsyncThunk(
    'testSelection/fetchAll',
    async () => {
        const response = await axiosInstance.get('/testSelections/')
        const normalized = normalize<
            schema.Array<TestSelection>,
            {
                testSelection: Record<string, TestSelection>
            }
        >(response.data.data, Schemas.TestSelections)

        return { entities: normalized.entities, message: response.data.message }
    }
)*/

export const fetchTestSelectionById = createAsyncThunk(
    'testSelection/fetchById',
    async (params: { id: EntityId; patientId: EntityId; caseId: EntityId }) => {
        const { id, patientId, caseId } = params

        const response = await axiosInstance.get(
            `/patients/${patientId}/cases/${caseId}/testSelections/${id}`
        )
        const normalized = normalize<
            schema.Entity<TestSelection>,
            {
                testSelection: Record<string, TestSelection>
                test: Record<string, Test>
            }
        >(response.data.data, Schemas.TestSelection)

        return { entities: normalized.entities, message: response.data.message }
    }
)

export const updateTestSelection = createAsyncThunk(
    'testSelection/update',
    async (params: {
        id: EntityId
        patientId: EntityId
        caseId: EntityId
        data: FormikValues
    }) => {
        const { id, data, patientId, caseId } = params

        const response = await axiosInstance.put(
            `/patients/${patientId}/cases/${caseId}/testSelections/${id}`,
            {
                ...data,
            }
        )
        const normalized = normalize<
            schema.Entity<TestSelection>,
            {
                testSelection: Record<string, TestSelection>
            }
        >(response.data.data, Schemas.TestSelection)

        return { entities: normalized.entities, message: response.data.message }
    }
)

export const createTestSelection = createAsyncThunk(
    'testSelection/create',
    async (params: {
        data: FormikValues
        patientId: EntityId
        caseId: EntityId
    }) => {
        const { data, patientId, caseId } = params

        const response = await axiosInstance.post(
            `/patients/${patientId}/cases/${caseId}/testSelections`,
            {
                ...data,
            }
        )
        const normalized = normalize<
            schema.Entity<TestSelection>,
            {
                testSelection: Record<string, TestSelection>
            }
        >(response.data.data, Schemas.TestSelection)

        return { entities: normalized.entities, message: response.data.message }
    }
)

export const deleteTestSelectionById = createAsyncThunk(
    'testSelection/delete',
    async (params: { id: EntityId; patientId: EntityId; caseId: EntityId }) => {
        const { id, patientId, caseId } = params

        const response = await axiosInstance.delete(
            `/patients/${patientId}/cases/${caseId}/testSelections/${id}`
        )

        return { id: id, message: response.data.message }
    }
)

const testSelectionSlice = createSlice({
    name: 'testSelection',
    initialState: testSelectionAdapter.getInitialState({}),
    reducers: {},
    extraReducers: (builder) => {
        /* builder.addCase(fetchAllTestSelections.fulfilled, (state, action) => {
            const { testSelection } = action.payload.entities

            if (testSelection) {
                testSelectionAdapter.upsertMany(state, testSelection)
            }
        })*/
        builder.addCase(fetchTestSelectionById.fulfilled, (state, action) => {
            const { testSelection } = action.payload.entities

            if (testSelection) {
                testSelectionAdapter.upsertMany(state, testSelection)
            }
        })
        builder.addCase(updateTestSelection.fulfilled, (state, action) => {
            const { testSelection } = action.payload.entities

            if (testSelection) {
                testSelectionAdapter.upsertMany(state, testSelection)
            }
        })
        builder.addCase(createTestSelection.fulfilled, (state, action) => {
            const { testSelection } = action.payload.entities

            if (testSelection) {
                testSelectionAdapter.upsertMany(state, testSelection)
            }
        })
        builder.addCase(deleteTestSelectionById.fulfilled, (state, action) => {
            const { id } = action.payload

            testSelectionAdapter.removeOne(state, +id)
        })
        builder.addCase(fetchCaseById.fulfilled, (state, action) => {
            const { testSelection } = action.payload.entities

            if (testSelection) {
                testSelectionAdapter.upsertMany(state, testSelection)
            }
        })
    },
})

export const {
    selectAll: selectAllTestSelections,
    selectById: selectTestSelectionById,
    selectIds: selectTestSelectionIds,
} = testSelectionAdapter.getSelectors<RootState>((state) => state.testSelection)

export default testSelectionSlice.reducer
