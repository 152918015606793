import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { ConnectedProps, connect } from 'react-redux'
import {
    IonButtons,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonButton,
    IonModal,
    IonList,
    IonSpinner,
    IonListHeader,
    IonSearchbar,
    IonCheckbox,
    IonPopover,
    IonGrid,
    IonRow,
    IonCol,
    IonTextarea,
    SearchbarCustomEvent,
} from '@ionic/react'
import { informationCircleOutline, removeCircleOutline } from 'ionicons/icons'
import { Formik, FormikProps, FormikValues } from 'formik'
import { isEmpty, without } from 'lodash'
import { selectCaseById, updateCase } from '../CaseSlice'
import { Virtuoso } from 'react-virtuoso'
import MyAccordion from '../../../components/MyAccordion'
import { searchIcf } from '../../../utils/IcfIcdHandler'
import DeleteDialog from '../../../components/DeleteDialog/DeleteDialog'
import AddDataButton from '../../../components/AddData/AddDataButton'
import { IcfIcdEntry, SearchResultIcf } from '../../../utils/IcfIcdType'
import { RootState } from '../../../store'
import { makeIcfsByCase } from '../selectors'

type CaseIcfProps = {
    disabled: boolean
    dataLoading: boolean
}

type CaseIcfState = {
    isOpen: boolean
    searchValue: string | null
    popoverOpen: string | boolean
    popoverContent?: IcfIcdEntry
    deleteDialogOpen: boolean
    searchResults?: SearchResultIcf
    optionToDelete: string | null
    isPersonalFactorsOpen: boolean
    deleteType?: string
}

type RouterProps = RouteComponentProps<{
    caseId: string
    patientId: string
}>

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = CaseIcfProps & RouterProps & PropsFromRedux

class CaseIcf extends React.Component<Props, CaseIcfState> {
    state: CaseIcfState = {
        isOpen: false,
        searchValue: null,
        deleteDialogOpen: false,
        isPersonalFactorsOpen: false,
        popoverOpen: false,
        optionToDelete: null,
    }
    componentDidMount() {
        this.initializeSearch()
    }

    initializeSearch = () => {
        let filter = searchIcf('')
        this.setState({ searchResults: filter })
    }

    handleSubmitData = (values: FormikValues) => {
        const { caseId, updateCase, patientId } = this.props

        const promises = [updateCase({ data: values, id: caseId, patientId })]

        Promise.all(promises).then((val) => {
            this.setState({ isOpen: false, isPersonalFactorsOpen: false })
        })
    }

    handleDeleteIcf = (code: string) => {
        const { thisCase, caseId, updateCase, patientId } = this.props

        const caseIcfs = without(thisCase?.caseIcfs, code)
        let values

        if (caseIcfs.length > 0) {
            values = {
                ...thisCase,
                caseIcfs: caseIcfs,
            }
        } else {
            values = {
                ...thisCase,
                caseIcfs: caseIcfs,
                personalFactors: null,
            }
        }

        const promises = [updateCase({ data: values, id: caseId, patientId })]

        Promise.all(promises)
    }

    handleDeletePersonalFactors = () => {
        const { thisCase, caseId, updateCase, patientId } = this.props

        const values = {
            ...thisCase,
            personalFactors: null,
        }
        const promises = [updateCase({ data: values, id: caseId, patientId })]

        Promise.all(promises)
    }

    handleSearchChange = (e: SearchbarCustomEvent) => {
        let filter = searchIcf(e.detail.value)
        this.setState({ searchResults: filter })
    }

    handleItemClick = (icf: IcfIcdEntry, props: FormikProps<FormikValues>) => {
        const { values, setFieldValue } = props
        let caseIcfs = [...values.caseIcfs]

        const index = caseIcfs.indexOf(icf.code)

        if (index > -1) {
            caseIcfs.splice(index, 1)
        } else {
            caseIcfs.push(icf.code)
        }

        setFieldValue('caseIcfs', caseIcfs, false)
    }

    deleteIcf = (code: string) => {
        this.setState({ deleteDialogOpen: true, optionToDelete: code })
    }

    deletePersonalFactors = (e: React.MouseEvent<HTMLIonButtonElement>) => {
        e.stopPropagation()
        this.setState({
            deleteDialogOpen: true,
            optionToDelete: 'Personenbezogene Faktoren',
            deleteType: 'personalFactors',
        })
    }

    renderDeleteDialog = () => {
        const { deleteDialogOpen, optionToDelete, deleteType } = this.state

        if (!optionToDelete) {
            return null
        }

        return (
            <>
                <DeleteDialog
                    header={optionToDelete + ' wirklich löschen?'}
                    onDelete={() => {
                        deleteType === 'personalFactors'
                            ? this.handleDeletePersonalFactors()
                            : this.handleDeleteIcf(optionToDelete)
                    }}
                    onDidDismiss={() =>
                        this.setState({ deleteDialogOpen: false })
                    }
                    isOpen={deleteDialogOpen}
                />
            </>
        )
    }

    renderIcf = (
        icf: IcfIcdEntry,
        index: number,
        showCheckbox: boolean = true,
        props?: FormikProps<FormikValues>
    ) => {
        if (!showCheckbox || !props) {
            return (
                <IonItem key={index}>
                    <>
                        {' '}
                        {icf.code} {' - '}
                        {icf.label}
                    </>
                    <IonButton
                        id={'info-' + icf.code}
                        onClick={() => {
                            this.setState({
                                popoverOpen: 'info-' + icf.code,
                                popoverContent: icf,
                            })
                        }}
                        shape="round"
                        fill="clear"
                        slot="end"
                    >
                        <IonIcon
                            slot="icon-only"
                            icon={informationCircleOutline}
                        />
                    </IonButton>
                    <IonButton
                        onClick={() => this.deleteIcf(icf.code)}
                        shape="round"
                        fill="clear"
                    >
                        <IonIcon
                            color="danger"
                            slot="icon-only"
                            icon={removeCircleOutline}
                        />
                    </IonButton>
                </IonItem>
            )
        }

        const { values } = props

        return (
            <IonItem
                key={index}
                onClick={() => this.handleItemClick(icf, props)}
            >
                <>
                    {' '}
                    {icf.code} {' - '}
                    {icf.label}
                </>
                <IonButton
                    id={'info-' + icf.code}
                    onClick={(e) => {
                        //e.stopPropagation()
                        e.stopPropagation()
                        this.setState({
                            popoverOpen: 'info-' + icf.code,
                            popoverContent: icf,
                        })
                    }}
                    shape="round"
                    fill="clear"
                    slot="end"
                >
                    <IonIcon slot="icon-only" icon={informationCircleOutline} />
                </IonButton>
                <IonCheckbox
                    checked={values['caseIcfs'].includes(icf.code)}
                    slot="end"
                ></IonCheckbox>
            </IonItem>
        )
    }

    renderSearchResults = (props: FormikProps<FormikValues>) => {
        if (!this.state.searchResults) {
            return null
        }

        const { items, itemCount, separationIndicies } =
            this.state.searchResults

        return (
            <IonList style={{ height: 'calc(100% - 102px)', paddingBottom: 0 }}>
                <Virtuoso
                    className="ion-content-scroll-host"
                    //style={{ height: '100%' }}
                    totalCount={itemCount}
                    itemContent={(index) => (
                        <div>
                            {index === separationIndicies[0] && (
                                <IonListHeader>Kapitel</IonListHeader>
                            )}
                            {index === separationIndicies[1] && (
                                <IonListHeader>Gruppe</IonListHeader>
                            )}
                            {index === separationIndicies[2] && (
                                <IonListHeader>Kategorie</IonListHeader>
                            )}
                            {index === separationIndicies[3] && (
                                <IonListHeader>Subkategorie 1</IonListHeader>
                            )}
                            {index === separationIndicies[4] && (
                                <IonListHeader>Subkategorie 2</IonListHeader>
                            )}
                            {this.renderIcf(items[index], index, true, props)}
                        </div>
                    )}
                />
            </IonList>
        )
    }
    renderPersonalFactorsModal = () => {
        const { thisCase } = this.props
        const { isPersonalFactorsOpen } = this.state

        return (
            <IonModal
                isOpen={isPersonalFactorsOpen}
                onDidDismiss={() => {
                    this.setState({ isPersonalFactorsOpen: false })
                }}
            >
                <Formik
                    initialValues={{ ...thisCase }}
                    onSubmit={this.handleSubmitData}
                >
                    {(props) => (
                        <form
                            onSubmit={props.handleSubmit}
                            className="full-width-form"
                        >
                            <IonHeader mode="ios">
                                <IonToolbar>
                                    <IonButtons slot="start">
                                        <IonButton
                                            color="medium"
                                            onClick={() =>
                                                this.setState({
                                                    isPersonalFactorsOpen:
                                                        false,
                                                })
                                            }
                                        >
                                            Abbrechen
                                        </IonButton>
                                    </IonButtons>
                                    <IonTitle>
                                        Personenbezogene Faktoren
                                    </IonTitle>
                                    <IonButtons slot="end">
                                        {props.isSubmitting ? (
                                            <IonSpinner />
                                        ) : (
                                            <IonButton
                                                onClick={props.submitForm}
                                            >
                                                Speichern
                                            </IonButton>
                                        )}
                                    </IonButtons>
                                </IonToolbar>
                            </IonHeader>
                            <IonContent>
                                <IonItem>
                                    <IonItem>
                                        <IonTextarea
                                            name="personalFactors"
                                            label="Personenbezogene Faktoren"
                                            labelPlacement="floating"
                                            autoGrow={true}
                                            onIonChange={props.handleChange}
                                            value={
                                                props.values.personalFactors ??
                                                ''
                                            }
                                        ></IonTextarea>
                                    </IonItem>
                                </IonItem>
                            </IonContent>
                        </form>
                    )}
                </Formik>
            </IonModal>
        )
    }

    renderModal = () => {
        const { thisCase } = this.props
        const { isOpen } = this.state

        return (
            <IonModal
                isOpen={isOpen}
                onDidDismiss={() => {
                    this.setState({ isOpen: false })
                }}
                className="fullscreen"
            >
                <Formik
                    initialValues={
                        { caseIcfs: thisCase?.caseIcfs ?? [] } as FormikValues
                    }
                    onSubmit={this.handleSubmitData}
                >
                    {(props) => (
                        <form
                            onSubmit={props.handleSubmit}
                            className="full-width-form"
                        >
                            <IonContent scrollY={false}>
                                <IonHeader mode="ios">
                                    <IonToolbar>
                                        <IonButtons slot="start">
                                            <IonButton
                                                color="medium"
                                                onClick={() =>
                                                    this.setState({
                                                        isOpen: false,
                                                    })
                                                }
                                            >
                                                Abbrechen
                                            </IonButton>
                                        </IonButtons>
                                        <IonTitle>ICF</IonTitle>
                                        <IonButtons slot="end">
                                            {props.isSubmitting ? (
                                                <IonSpinner />
                                            ) : (
                                                <IonButton
                                                    onClick={props.submitForm}
                                                >
                                                    Speichern
                                                </IonButton>
                                            )}
                                        </IonButtons>
                                    </IonToolbar>
                                </IonHeader>
                                <IonContent scrollY={false}>
                                    <IonGrid
                                        style={{ height: 'calc(100% - 44px)' }}
                                    >
                                        <IonRow style={{ height: '100%' }}>
                                            <IonCol size="12" size-sm="4">
                                                <IonContent>
                                                    <h3>
                                                        Anamnestische
                                                        Fallanalyse
                                                    </h3>
                                                    <p>
                                                        {!isEmpty(
                                                            thisCase?.anamnesticCaseAnalysis
                                                        )
                                                            ? thisCase?.anamnesticCaseAnalysis
                                                            : 'Keine Anamnestische Fallanalyse angegeben'}
                                                    </p>
                                                </IonContent>
                                            </IonCol>
                                            <IonCol size="12" size-sm="8">
                                                <IonContent scrollY={false}>
                                                    <IonSearchbar
                                                        onIonInput={
                                                            this
                                                                .handleSearchChange
                                                        }
                                                        autocapitalize="off"
                                                        //style={{ height: 'fit-content' }}
                                                    />
                                                    {this.renderSearchResults(
                                                        props
                                                    )}
                                                </IonContent>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonContent>
                            </IonContent>
                        </form>
                    )}
                </Formik>
            </IonModal>
        )
    }

    renderAddData = () => {
        const { disabled, thisCase } = this.props

        return (
            <IonGrid>
                <IonRow class="ion-justify-content-center">
                    <div
                        style={{
                            padding: '10px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <p>ICF hinzufügen</p>
                        <AddDataButton
                            disabled={disabled}
                            onClick={() => this.setState({ isOpen: true })}
                        />
                    </div>
                    {!thisCase?.personalFactors && (
                        <div
                            style={{
                                padding: '10px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <p>Personenbezogene Faktoren</p>
                            <AddDataButton
                                disabled={
                                    disabled || isEmpty(thisCase?.caseIcfs)
                                }
                                onClick={() =>
                                    this.setState({
                                        isPersonalFactorsOpen: true,
                                    })
                                }
                            />
                        </div>
                    )}
                </IonRow>
            </IonGrid>
        )
    }

    renderCaseIcfs = () => {
        const { thisCase, icfsByCase } = this.props

        // return <IonList>{Object.values(thisCase['caseIcfs']).map(icf)}</IonList>
        return (
            <>
                <IonList>
                    {!isEmpty(icfsByCase['chapter']) && (
                        <>
                            <IonListHeader>Kapitel</IonListHeader>
                            {icfsByCase['chapter'].map((icf, index) =>
                                this.renderIcf(icf, index, false)
                            )}
                        </>
                    )}
                    {!isEmpty(icfsByCase['block']) && (
                        <>
                            <IonListHeader>Gruppe</IonListHeader>
                            {icfsByCase['block'].map((icf, index) =>
                                this.renderIcf(icf, index, false)
                            )}
                        </>
                    )}
                    {!isEmpty(icfsByCase['fourdigit']) && (
                        <>
                            <IonListHeader>Kategorie</IonListHeader>
                            {icfsByCase['fourdigit'].map((icf, index) =>
                                this.renderIcf(icf, index, false)
                            )}
                        </>
                    )}
                    {!isEmpty(icfsByCase['fivedigit']) && (
                        <>
                            <IonListHeader>Subkategorie 1</IonListHeader>
                            {icfsByCase['fivedigit'].map((icf, index) =>
                                this.renderIcf(icf, index, false)
                            )}
                        </>
                    )}
                    {!isEmpty(icfsByCase['sixdigit']) && (
                        <>
                            <IonListHeader>Subkategorie 2</IonListHeader>
                            {icfsByCase['sixdigit'].map((icf, index) =>
                                this.renderIcf(icf, index, false)
                            )}
                        </>
                    )}
                    {!isEmpty(thisCase?.personalFactors) && (
                        <>
                            <IonListHeader>
                                Personenbezogene Faktoren (i)
                            </IonListHeader>
                            <IonItem
                                button
                                onClick={() => {
                                    this.setState({
                                        isPersonalFactorsOpen: true,
                                    })
                                }}
                            >
                                <IonLabel class="ion-text-wrap">
                                    {thisCase?.personalFactors}
                                </IonLabel>
                                <IonButton
                                    onClick={(e) =>
                                        this.deletePersonalFactors(e)
                                    }
                                    shape="round"
                                    fill="clear"
                                >
                                    <IonIcon
                                        color="danger"
                                        slot="icon-only"
                                        icon={removeCircleOutline}
                                    />
                                </IonButton>
                            </IonItem>
                        </>
                    )}
                </IonList>
                {this.renderAddData()}
                {this.renderDeleteDialog()}
            </>
        )
    }

    render() {
        const { dataLoading, thisCase } = this.props
        const { popoverOpen, popoverContent } = this.state

        if (dataLoading) {
            return null
        }

        return (
            <>
                <MyAccordion
                    title={`ICF (${
                        thisCase?.caseIcfs ? thisCase['caseIcfs'].length : '0'
                    })`}
                >
                    {thisCase?.caseIcfs
                        ? this.renderCaseIcfs()
                        : this.renderAddData()}
                </MyAccordion>
                <IonPopover
                    isOpen={Boolean(popoverOpen)}
                    onDidDismiss={() => this.setState({ popoverOpen: false })}
                    side="left"
                    alignment="center"
                    className="popover-content"
                >
                    <IonContent class="ion-padding">
                        <div>
                            <h3>{popoverContent?.code}</h3>
                            <p>{popoverContent?.label}</p>
                            {popoverContent?.codingHint && (
                                <h4>Kodierungsempfehlung</h4>
                            )}
                            {popoverContent?.codingHint &&
                            typeof popoverContent?.codingHint === 'string' ? (
                                <p>{popoverContent.codingHint}</p>
                            ) : (
                                typeof popoverContent?.codingHint ===
                                    'object' &&
                                Array.isArray(
                                    popoverContent?.codingHint?.paras
                                ) &&
                                popoverContent?.codingHint?.paras?.map(
                                    (para, index) => (
                                        <p key={index}>
                                            {para.class === 'bold' ? (
                                                <b>{para.text}</b>
                                            ) : (
                                                para.text
                                            )}
                                        </p>
                                    )
                                )
                            )}
                            {popoverContent?.exclusion && <h4>Ausschluss</h4>}
                            {popoverContent?.exclusion && (
                                <p>{popoverContent.exclusion}</p>
                            )}

                            {popoverContent?.inclusion && <h4>Einschluss</h4>}
                            {popoverContent?.inclusion && (
                                <p>{popoverContent.inclusion}</p>
                            )}
                        </div>
                    </IonContent>
                </IonPopover>
                {this.renderModal()}
                {this.renderPersonalFactorsModal()}
            </>
        )
    }
}

const makeMapState = () => {
    const getIcfsByCase = makeIcfsByCase()

    return (state: RootState, ownProps: RouterProps) => {
        const caseId = parseInt(ownProps.match.params.caseId)
        const patientId = parseInt(ownProps.match.params.patientId)
        const icfsByCase = getIcfsByCase(state, caseId)
        const caseById = selectCaseById(state, caseId)

        return {
            icfsByCase,
            thisCase: caseById,
            caseId,
            patientId,
        }
    }
}

const mapDispatch = {
    updateCase,
}

const connector = connect(makeMapState, mapDispatch)

export default withRouter(connector(CaseIcf))
