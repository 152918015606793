import {
    createAsyncThunk,
    createEntityAdapter,
    createSlice,
    EntityId,
} from '@reduxjs/toolkit'
import { Aim } from './AimType'
import { RootState } from '../../store'
import axiosInstance from '../../utils/ApiConfig'
import { normalize, schema } from 'normalizr'
import { Schemas } from '../../schemas'
import { FormikValues } from 'formik'
import { fetchCaseById } from '../Case/CaseSlice'

const aimAdapter = createEntityAdapter<Aim>({
    sortComparer: (a, b) => a.id - b.id,
})

export const fetchAllAims = createAsyncThunk(
    'aim/fetchAll',
    async (params: { patientId: EntityId; caseId: EntityId }) => {
        const { patientId, caseId } = params

        const response = await axiosInstance.get(
            `/patients/${patientId}/cases/${caseId}/aims/`
        )
        const normalized = normalize<
            schema.Array<Aim>,
            {
                aim: Record<string, Aim>
            }
        >(response.data.data, Schemas.Aims)

        return { entities: normalized.entities, message: response.data.message }
    }
)

export const fetchAimById = createAsyncThunk(
    'aim/fetchById',
    async (params: { id: EntityId; patientId: EntityId; caseId: EntityId }) => {
        const { id, caseId, patientId } = params

        const response = await axiosInstance.get(
            `/patients/${patientId}/cases/${caseId}/aims/${id}`
        )
        const normalized = normalize<
            schema.Entity<Aim>,
            {
                aim: Record<string, Aim>
            }
        >(response.data.data, Schemas.Aim)

        return { entities: normalized.entities, message: response.data.message }
    }
)

export const updateAim = createAsyncThunk(
    'aim/update',
    async (params: {
        id: EntityId
        data: FormikValues
        patientId: EntityId
        caseId: EntityId
    }) => {
        const { id, data, patientId, caseId } = params
        const response = await axiosInstance.put(
            `/patients/${patientId}/cases/${caseId}/aims/${id}`,
            {
                ...data,
            }
        )
        const normalized = normalize<
            schema.Entity<Aim>,
            {
                aim: Record<string, Aim>
            }
        >(response.data.data, Schemas.Aim)

        return { entities: normalized.entities, message: response.data.message }
    }
)

export const createAim = createAsyncThunk(
    'aim/create',
    async (params: {
        data: FormikValues
        patientId: EntityId
        caseId: EntityId
    }) => {
        const { data, caseId, patientId } = params
        const response = await axiosInstance.post(
            `/patients/${patientId}/cases/${caseId}/aims/`,
            {
                ...data,
            }
        )
        const normalized = normalize<
            schema.Entity<Aim>,
            {
                aim: Record<string, Aim>
            }
        >(response.data.data, Schemas.Aim)

        return { entities: normalized.entities, message: response.data.message }
    }
)

export const deleteAimById = createAsyncThunk(
    'aim/delete',
    async (params: { id: EntityId; patientId: EntityId; caseId: EntityId }) => {
        const { id, caseId, patientId } = params

        const response = await axiosInstance.delete(
            `/patients/${patientId}/cases/${caseId}/aims/${id}`
        )

        return { id: id, message: response.data.message }
    }
)

const aimSlice = createSlice({
    name: 'aim',
    initialState: aimAdapter.getInitialState({}),
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchAllAims.fulfilled, (state, action) => {
            const { aim } = action.payload.entities

            if (aim) {
                aimAdapter.upsertMany(state, aim)
            }
        })
        builder.addCase(fetchAimById.fulfilled, (state, action) => {
            const { aim } = action.payload.entities

            if (aim) {
                aimAdapter.upsertMany(state, aim)
            }
        })
        builder.addCase(updateAim.fulfilled, (state, action) => {
            const { aim } = action.payload.entities

            if (aim) {
                aimAdapter.upsertMany(state, aim)
            }
        })
        builder.addCase(createAim.fulfilled, (state, action) => {
            const { aim } = action.payload.entities

            if (aim) {
                aimAdapter.upsertMany(state, aim)
            }
        })
        builder.addCase(deleteAimById.fulfilled, (state, action) => {
            const { id } = action.payload

            aimAdapter.removeOne(state, +id)
        })
        builder.addCase(fetchCaseById.fulfilled, (state, action) => {
            const { aim } = action.payload.entities

            if (aim) {
                aimAdapter.upsertMany(state, aim)
            }
        })
    },
})

export const {
    selectAll: selectAllAims,
    selectById: selectAimById,
    selectIds: selectAimIds,
} = aimAdapter.getSelectors<RootState>((state) => state.aim)

export default aimSlice.reducer
