import React, { FC } from 'react'
import { IonButton, IonItem, IonTextarea } from '@ionic/react'
import { useParams } from 'react-router-dom'
import { useFormikContext } from 'formik'
import { Inspection } from '../../../Inspection/InspectionType'

const InspectionForm: FC = () => {
    const { caseId, patientId } = useParams<{
        caseId: string
        patientId: string
    }>()
    const { values, handleChange, submitForm } = useFormikContext<Inspection>()

    return (
        <>
            <IonItem>
                <IonTextarea
                    name="inspection"
                    label="Inspektion"
                    labelPlacement="floating"
                    autoGrow={true}
                    onIonChange={handleChange}
                    value={values.inspection ?? ''}
                ></IonTextarea>
            </IonItem>
            <IonItem>
                <IonTextarea
                    name="inspectionHint"
                    label="Relevante Hinweise aus der Inspektion und die Planung der weiteren Untersuchung"
                    labelPlacement="floating"
                    autoGrow={true}
                    onIonChange={handleChange}
                    value={values.inspectionHint ?? ''}
                ></IonTextarea>
            </IonItem>
            <IonButton href={`/patients/${patientId}/cases/${caseId}`}>
                Abbrechen
            </IonButton>
            <IonButton onClick={submitForm}>Speichern</IonButton>
        </>
    )
}

export default InspectionForm
