import React from 'react'
import { Route, useRouteMatch } from 'react-router-dom'
import { IonPage, IonRouterOutlet } from '@ionic/react'
import CaseLoader from './components/CaseLoader'

const Case = (): JSX.Element => {
    const { path } = useRouteMatch()

    return (
        <IonPage>
            <IonRouterOutlet>
                <Route path={`${path}/:caseId`} component={CaseLoader} />
            </IonRouterOutlet>
        </IonPage>
    )
}

export default Case
