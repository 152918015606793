import { IonRouterOutlet } from '@ionic/react'
import React from 'react'

import UserList from './UserList'
import { Route } from 'react-router-dom'
import UserDetail from './UserDetail'
import { IonPage } from '@ionic/react'
import Register from '../Register/Register'

const User = (): JSX.Element => {
    return (
        <IonPage>
            <IonRouterOutlet>
                <Route exact path="/users" component={UserList} />
                <Route path="/users/:id" component={UserDetail} />
                <Route exact path="/users/register" component={Register} />
            </IonRouterOutlet>
        </IonPage>
    )
}

export default User
