import icfData from '../data/icf.json'
import icdData from '../data/icd.json'
import icfDataObjects from '../data/icf_objects.json'
import icdDataObjects from '../data/icd_objects.json'
import { isEmpty, concat, filter } from 'lodash'
import { IcdFilterEnum, IcfFilterEnum, IcfIcdModeEnum } from './Enum/IcfIcdEnum'
import {
    IcdData,
    IcfData,
    IcfIcdEntry,
    SearchResultIcd,
    SearchResultIcf,
} from './IcfIcdType'

const filterIcfIcd = (
    filter: IcfFilterEnum | IcdFilterEnum,
    value: string | null | undefined,
    mode: IcfIcdModeEnum
): IcfIcdEntry[] => {
    if (!mode) {
        console.error('Weder ICF noch ICD als mode angegeben.')
    }

    if (mode === IcfIcdModeEnum.MODE_ICF) {
        const data = icfData as IcfData

        return data[filter as IcfFilterEnum].filter((chapter) => {
            if (isEmpty(value) || !value) {
                return true
            }
            return (
                chapter.code.toLowerCase().startsWith(value.toLowerCase()) ||
                chapter.label.toLowerCase().includes(value.toLowerCase())
            )
        })
    }
    if (mode === IcfIcdModeEnum.MODE_ICD) {
        const data = icdData as IcdData

        return data[filter as IcdFilterEnum].filter((chapter) => {
            if (isEmpty(value) || !value) {
                return true
            }
            return (
                chapter.code.toLowerCase().startsWith(value.toLowerCase()) ||
                chapter.label.toLowerCase().includes(value.toLowerCase())
            )
        })
    }
    return []
}

export const searchIcd = (searchValue: string): SearchResultIcd => {
    let filteredIcdCategories = filterIcfIcd(
        IcdFilterEnum.CATEGORY,
        searchValue,
        IcfIcdModeEnum.MODE_ICD
    )

    return {
        // category: filteredIcdCategories,
        items: filteredIcdCategories,
        itemsCount: filteredIcdCategories.length,
    }
}

export const searchIcf = (searchValue?: string | null): SearchResultIcf => {
    let filteredIcfChapters = filterIcfIcd(
        IcfFilterEnum.CHAPTER,
        searchValue,
        IcfIcdModeEnum.MODE_ICF
    )
    let filteredIcfBlocks = filterIcfIcd(
        IcfFilterEnum.BLOCK,
        searchValue,
        IcfIcdModeEnum.MODE_ICF
    )
    let filteredIcfFourdigits = filterIcfIcd(
        IcfFilterEnum.FOURDIGIT,
        searchValue,
        IcfIcdModeEnum.MODE_ICF
    )
    let filteredIcfFivedigits = filterIcfIcd(
        IcfFilterEnum.FIVEDIGIT,
        searchValue,
        IcfIcdModeEnum.MODE_ICF
    )
    let filteredIcfSixdigits = filterIcfIcd(
        IcfFilterEnum.SIXDIGIT,
        searchValue,
        IcfIcdModeEnum.MODE_ICF
    )

    const items = concat(
        filteredIcfChapters,
        filteredIcfBlocks,
        filteredIcfFourdigits,
        filteredIcfFivedigits,
        filteredIcfSixdigits
    )

    return {
        chapter: filteredIcfChapters,
        block: filteredIcfBlocks,
        fourdigit: filteredIcfFourdigits,
        fivedigit: filteredIcfFivedigits,
        sixdigit: filteredIcfSixdigits,
        items: items,
        itemCount: items.length,
        separationIndicies: [
            0,
            filteredIcfChapters.length,
            filteredIcfChapters.length + filteredIcfBlocks.length,
            filteredIcfChapters.length +
                filteredIcfBlocks.length +
                filteredIcfFourdigits.length,
            filteredIcfChapters.length +
                filteredIcfBlocks.length +
                filteredIcfFourdigits.length +
                filteredIcfFivedigits.length,
        ],
    }
}

export const exactSearch = (
    searchValue: string,
    mode: IcfIcdModeEnum
): IcfIcdEntry | null => {
    let out: IcfIcdEntry[] = []
    if (mode === IcfIcdModeEnum.MODE_ICF) {
        if (searchValue.length === 2) {
            out = filterIcfIcd(
                IcfFilterEnum.CHAPTER,
                searchValue,
                IcfIcdModeEnum.MODE_ICF
            )
        } else if (searchValue.length === 4) {
            out = filterIcfIcd(
                IcfFilterEnum.FOURDIGIT,
                searchValue,
                IcfIcdModeEnum.MODE_ICF
            )
        } else if (searchValue.length === 5) {
            out = filterIcfIcd(
                IcfFilterEnum.FIVEDIGIT,
                searchValue,
                IcfIcdModeEnum.MODE_ICF
            )
        } else if (searchValue.length === 6) {
            out = filterIcfIcd(
                IcfFilterEnum.SIXDIGIT,
                searchValue,
                IcfIcdModeEnum.MODE_ICF
            )
        } else {
            out = filterIcfIcd(
                IcfFilterEnum.BLOCK,
                searchValue,
                IcfIcdModeEnum.MODE_ICF
            )
        }
    } else if (mode === IcfIcdModeEnum.MODE_ICD) {
        out = filterIcfIcd(
            IcdFilterEnum.CATEGORY,
            searchValue,
            IcfIcdModeEnum.MODE_ICD
        )
    }

    return out.length > 0 ? out[0] : null
}

export const findAllByMode = (input: string, mode: IcfIcdModeEnum) => {
    if (isEmpty(input)) {
        return []
    }
    const data =
        mode === IcfIcdModeEnum.MODE_ICF ? icfDataObjects : icdDataObjects

    return filter(data, (object) => {
        return input.includes(object.code)
    })
}
