import React, { JSX } from 'react'
import { Route, useRouteMatch } from 'react-router-dom'
import { IonPage, IonRouterOutlet } from '@ionic/react'
import TestEdit from './TestEdit'
import TestList from './TestList'

const Test = (): JSX.Element => {
    const match = useRouteMatch()

    return (
        <IonPage>
            <IonRouterOutlet>
                <Route exact path={`${match.path}`} component={TestList} />
                <Route path={`${match.path}/new`} component={TestEdit} />
                <Route
                    path={`${match.path}/edit/:testId`}
                    component={TestEdit}
                />
            </IonRouterOutlet>
        </IonPage>
    )
}

export default Test
