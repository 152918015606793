import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar,
} from '@ionic/react'
import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { ConnectedProps, connect } from 'react-redux'
import { isEmpty } from 'lodash'
import { addOutline, trashOutline } from 'ionicons/icons'
import DeleteDialog from '../../components/DeleteDialog/DeleteDialog'
import {
    fetchAllPatients,
    deletePatientById,
    selectAllPatients,
} from './PatientSlice'
import { RootState } from '../../store'
import { Patient } from './PatientType'

// function calculateAge(birthday) {
//     // birthday is a date
//     var ageDifMs = Date.now() - birthday.getTime()
//     var ageDate = new Date(ageDifMs) // miliseconds from epoch
//     return Math.abs(ageDate.getUTCFullYear() - 1970)
// }

type PatientListProps = {}

type PatientListState = {
    dataLoading: boolean
    deleteDialogOpen: boolean
    optionToDelete?: number
}

type RouterProps = RouteComponentProps

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PatientListProps & PropsFromRedux & RouterProps

class PatientList extends React.Component<Props, PatientListState> {
    state: PatientListState = { dataLoading: false, deleteDialogOpen: false }

    componentDidMount() {
        const { fetchAllPatients } = this.props

        this.setState({ dataLoading: true })
        Promise.all([fetchAllPatients()])
            .then(() => {
                this.setState({ dataLoading: false })
            })
            .catch(() => {
                this.setState({ dataLoading: false })
            })
    }

    handleDelete = (
        e: React.MouseEvent<HTMLIonButtonElement, MouseEvent>,
        id: number
    ) => {
        e.preventDefault()
        e.stopPropagation()
        this.setState({ deleteDialogOpen: true, optionToDelete: id })
    }

    handleDeletePatient = async (id: number) => {
        const { deletePatientById } = this.props

        let out = await deletePatientById({ id })
        console.log(out)
        this.setState({ deleteDialogOpen: false, optionToDelete: undefined })
    }

    renderListItem = (item: Patient, index: number) => {
        const { history } = this.props

        const gender =
            item.gender === 'm'
                ? 'männlich'
                : item.gender === 'f'
                  ? 'weiblich'
                  : 'divers'
        // const age = calculateAge(
        //     new Date(
        //         '' +
        //             item.birthDay +
        //             '.' +
        //             item.birthMonth +
        //             '.' +
        //             item.birthYear
        //     )
        // )
        const address =
            item.street +
            ' ' +
            item.houseNumber +
            ', ' +
            item.zip +
            ' ' +
            item.city

        return (
            <IonItem
                key={index}
                button
                onClick={(e) => {
                    e.preventDefault()
                    history.push('/patients/' + item.id)
                }}
            >
                <IonLabel>
                    <h2>
                        {item.lastName}, {item.firstName} - {gender} -{' '}
                        {item.yearOfBirth}
                    </h2>
                    <h3>{address}</h3>
                    <p>{item.occupation}</p>
                </IonLabel>
                <IonButton
                    fill="clear"
                    onClick={(e) => this.handleDelete(e, item.id)}
                >
                    <IonIcon
                        color="danger"
                        slot="icon-only"
                        style={{ height: '100%' }}
                        icon={trashOutline}
                    />
                </IonButton>
            </IonItem>
        )
    }

    renderDeleteDialog = () => {
        const { patients } = this.props
        const { deleteDialogOpen, optionToDelete } = this.state

        if (!optionToDelete || !patients[optionToDelete]) {
            return null
        }

        return (
            <>
                <DeleteDialog
                    header={
                        'Patient  ' +
                        patients[optionToDelete].firstName +
                        ' ' +
                        patients[optionToDelete].lastName +
                        ' wirklich löschen?'
                    }
                    onDelete={() => {
                        this.handleDeletePatient(optionToDelete)
                    }}
                    onDidDismiss={() =>
                        this.setState({ deleteDialogOpen: false })
                    }
                    isOpen={deleteDialogOpen}
                />
            </>
        )
    }

    renderList = () => {
        const { patients } = this.props

        return (
            <IonList>
                <IonListHeader>
                    <IonLabel>
                        <h1>Patienten</h1>
                    </IonLabel>
                    <IonButton routerLink="/patients/new" shape="round">
                        <IonIcon slot="icon-only" icon={addOutline} />
                    </IonButton>
                </IonListHeader>
                {patients.map(this.renderListItem)}
            </IonList>
        )
    }

    renderEmptyPatients = () => {
        return (
            <IonGrid>
                <IonRow class="ion-justify-content-center ion-align-items-center">
                    <p>Keine Patienten vorhanden</p>
                </IonRow>

                <IonRow class="ion-justify-content-center ion-align-items-center">
                    <IonButton routerLink="/patients/new" shape="round">
                        <IonIcon slot="icon-only" icon={addOutline} />
                    </IonButton>
                </IonRow>
            </IonGrid>
        )
    }

    render() {
        const { patients } = this.props
        const { dataLoading } = this.state

        if (dataLoading) {
            return null
        }

        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonBackButton
                                text=""
                                defaultHref={`/dashboard/`}
                            ></IonBackButton>
                        </IonButtons>
                        <IonTitle>Patientenübersicht</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonContent fullscreen color="light">
                    <IonCard>
                        <IonCardContent>
                            {isEmpty(patients)
                                ? this.renderEmptyPatients()
                                : this.renderList()}
                            {this.renderDeleteDialog()}
                        </IonCardContent>
                    </IonCard>
                </IonContent>
            </IonPage>
        )
    }
}
const mapState = (state: RootState) => {
    return {
        patients: selectAllPatients(state),
    }
}

const mapDispatch = { fetchAllPatients, deletePatientById }

const connector = connect(mapState, mapDispatch)

export default withRouter(connector(PatientList))
