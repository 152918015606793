import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { ConnectedProps, connect } from 'react-redux'
import {
    IonIcon,
    IonItem,
    IonText,
    IonLabel,
    IonButton,
    IonList,
    IonListHeader,
    IonChip,
} from '@ionic/react'
import { removeCircleOutline } from 'ionicons/icons'
import { isEmpty } from 'lodash'
import {
    deleteDocumentationById,
    selectAllDocumentations,
} from '../../Documentation/DocumentationSlice'
import { fetchCaseById, selectCaseById } from '../CaseSlice'
import moment from 'moment'
import 'moment/locale/de'
import MyAccordion from '../../../components/MyAccordion'
import DeleteDialog from '../../../components/DeleteDialog/DeleteDialog'
import AddDataButton from '../../../components/AddData/AddDataButton'
import { RootState } from '../../../store'
import { EntityId } from '@reduxjs/toolkit'
import { makeDocumentationsByCaseAndRejected } from '../selectors'

type DocumentationProps = {
    disabled: boolean
    dataLoading: boolean
}

type DocumentationState = {
    deleteDialogOpen: boolean
    optionToDelete: EntityId | null
}

type RouterProps = RouteComponentProps<{
    caseId: string
    patientId: string
}>

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = DocumentationProps & RouterProps & PropsFromRedux

class Documentation extends React.Component<Props, DocumentationState> {
    state: DocumentationState = {
        deleteDialogOpen: false,
        optionToDelete: null,
    }

    componentDidMount() {}

    deleteDocumentation = (id: EntityId) => {
        this.setState({ deleteDialogOpen: true, optionToDelete: id })
    }

    handleDeleteDocumentation = async (id: EntityId) => {
        const { deleteDocumentationById, caseId, patientId } = this.props

        await deleteDocumentationById({ id, caseId, patientId })
        this.setState({ deleteDialogOpen: false, optionToDelete: null })
    }

    renderAddData = () => {
        const { match, disabled } = this.props

        return (
            <div className="index-main">
                <p style={{ paddingTop: '16px' }}>Dokumentation hinzufügen</p>
                <AddDataButton
                    disabled={disabled}
                    routerLink={`${match.url}/documentations/new`}
                />
            </div>
        )
    }

    renderDeleteDialog = () => {
        const { deleteDialogOpen, optionToDelete } = this.state

        if (!optionToDelete) {
            return null
        }

        return (
            <>
                <DeleteDialog
                    header={
                        'Dokumentation ' + optionToDelete + ' wirklich löschen?'
                    }
                    onDelete={() => {
                        this.handleDeleteDocumentation(optionToDelete)
                    }}
                    onDidDismiss={() =>
                        this.setState({ deleteDialogOpen: false })
                    }
                    isOpen={deleteDialogOpen}
                />
            </>
        )
    }

    renderDocumentation = (id: number, index: number) => {
        const { history, match, documentationsAsObject } = this.props

        const documentation = documentationsAsObject[id]
        const rejectedTestsCount = documentation?.rejectedTests?.length ?? 0
        // const activeTestsCount =
        //     (documentation?.tests?.length ?? 0) -
        //     (documentation?.rejectedTests?.length ?? 0)

        if (!documentation) {
            return <></>
        }

        const testLabel = !isEmpty(documentation?.tests)
            ? `${
                  documentation!.tests!.length === 1
                      ? '1 Test'
                      : documentation!.tests!.length + ' Tests'
              } ${
                  rejectedTestsCount > 0
                      ? ' (' + rejectedTestsCount + ' verworfen)'
                      : ''
              }`
            : null

        return (
            <IonItem
                key={index}
                button
                onClick={(e) => {
                    e.preventDefault()
                    history.push(
                        `${match.url}/documentations/${documentation.id}`
                    )
                }}
            >
                <IonLabel>
                    <div>
                        <IonText>
                            {moment(documentation.date).format('llll')}
                        </IonText>
                    </div>
                    {(testLabel ||
                        !isEmpty(documentation.documentationIcfs)) && (
                        <div style={{ paddingTop: '8px' }}>
                            <IonText>
                                {testLabel && (
                                    <IonChip color="secondary">
                                        {testLabel}
                                    </IonChip>
                                )}
                                {!isEmpty(documentation.documentationIcfs) &&
                                    Object.values(
                                        documentation.documentationIcfs
                                    ).map((icf, index) => {
                                        return (
                                            <IonChip
                                                key={index}
                                                color="tertiary"
                                            >
                                                {icf}
                                            </IonChip>
                                        )
                                    })}
                            </IonText>
                        </div>
                    )}
                </IonLabel>
                <IonButton
                    onClick={(e) => {
                        e.stopPropagation()
                        this.deleteDocumentation(documentation.id)
                    }}
                    shape="round"
                    fill="clear"
                    slot="end"
                >
                    <IonIcon
                        color="danger"
                        slot="icon-only"
                        icon={removeCircleOutline}
                    />
                </IonButton>
            </IonItem>
        )
    }

    renderDocumentations = () => {
        const { thisCase } = this.props

        if (!thisCase) {
            return null
        }

        return (
            <>
                {!isEmpty(thisCase.documentations) && (
                    <IonList>
                        <IonListHeader>
                            <IonLabel>Dokumentationen</IonLabel>
                        </IonListHeader>
                        {thisCase.documentations.map((value, index) =>
                            this.renderDocumentation(value, index)
                        )}
                    </IonList>
                )}
                {this.renderAddData()}
                {this.renderDeleteDialog()}
            </>
        )
    }

    render() {
        const { dataLoading, thisCase } = this.props

        if (dataLoading || !thisCase) {
            return null
        }

        const dataAvailable = !isEmpty(thisCase.documentations)

        return (
            <>
                <MyAccordion
                    title={`Dokumentation (${
                        dataAvailable
                            ? Object.keys(thisCase.documentations).length
                            : '0'
                    })`}
                >
                    {dataAvailable
                        ? this.renderDocumentations()
                        : this.renderAddData()}
                </MyAccordion>
                {/*this.renderModal()*/}
            </>
        )
    }
}

const makeMapState = () => {
    const getDocumentationsByCaseAndRejected =
        makeDocumentationsByCaseAndRejected()

    return (state: RootState, ownProps: RouterProps) => {
        const caseId = parseInt(ownProps.match.params.caseId)
        const patientId = parseInt(ownProps.match.params.patientId)
        const match = ownProps.match

        const documentationByCaseAndRejected =
            getDocumentationsByCaseAndRejected(state, caseId)

        return {
            caseId: caseId,
            rejectedDocumentations: documentationByCaseAndRejected['rejected'],
            notRejectedDocumentations:
                documentationByCaseAndRejected['notRejected'],
            documentationsWithoutTest: documentationByCaseAndRejected['noTest'],
            thisCase: selectCaseById(state, caseId),
            match: match,
            documentations: selectAllDocumentations(state),
            documentationsAsObject: state.documentation?.entities,
            patientId,
        }
    }
}

const mapDispatch = {
    fetchCaseById,
    deleteDocumentationById,
}

const connector = connect(makeMapState, mapDispatch)

export default withRouter(connector(Documentation))
