import {
    createAsyncThunk,
    createEntityAdapter,
    createSlice,
    EntityId,
} from '@reduxjs/toolkit'
import { Inspection } from './InspectionType'
import { RootState } from '../../store'
import axiosInstance from '../../utils/ApiConfig'
import { normalize, schema } from 'normalizr'
import { Schemas } from '../../schemas'
import { fetchCaseById } from '../Case/CaseSlice'
import { FormikValues } from 'formik'

const inspectionAdapter = createEntityAdapter<Inspection>({
    sortComparer: (a, b) => a.id - b.id,
})

export const createInspection = createAsyncThunk(
    'inspection/create',
    async (params: {
        data: FormikValues
        patientId: EntityId
        caseId: EntityId
    }) => {
        const { data, patientId, caseId } = params

        const response = await axiosInstance.post(
            `/patients/${patientId}/cases/${caseId}/inspections/`,
            { ...data }
        )
        const normalized = normalize<
            schema.Entity<Inspection>,
            { inspection: Record<string, Inspection> }
        >(response.data.data, Schemas.Inspection)

        return { entities: normalized.entities, statusCode: response.status }
    }
)

export const updateInspection = createAsyncThunk(
    'inspection/update',
    async (params: {
        id: EntityId
        data: FormikValues
        patientId: EntityId
        caseId: EntityId
    }) => {
        const { id, data, patientId, caseId } = params

        const response = await axiosInstance.put(
            `/patients/${patientId}/cases/${caseId}/inspections/${id}`,
            { ...data }
        )
        const normalized = normalize<
            schema.Entity<Inspection>,
            { inspection: Record<string, Inspection> }
        >(response.data.data, Schemas.Inspection)

        return { entities: normalized.entities, statusCode: response.status }
    }
)

export const fetchInspectionByCase = createAsyncThunk(
    'inspection/fetchById',
    async (params: { id: EntityId; patientId: EntityId; caseId: EntityId }) => {
        const { patientId, caseId, id } = params

        const response = await axiosInstance.get(
            `/patients/${patientId}/cases/${caseId}/inspections/${id}`
        )

        const normalized = normalize<
            schema.Entity<Inspection>,
            { inspection: Record<string, Inspection> }
        >(response.data.data, Schemas.Inspection)

        return { entities: normalized.entities }
    }
)

const inspectionSlice = createSlice({
    name: 'inspection',
    initialState: inspectionAdapter.getInitialState({}),
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchCaseById.fulfilled, (state, action) => {
            const { inspection } = action.payload.entities

            if (inspection) {
                inspectionAdapter.upsertMany(state, inspection)
            }
        })
        builder.addCase(fetchInspectionByCase.fulfilled, (state, action) => {
            const { inspection } = action.payload.entities

            if (inspection) {
                inspectionAdapter.upsertMany(state, inspection)
            }
        })
        builder.addCase(createInspection.fulfilled, (state, action) => {
            const { inspection } = action.payload.entities

            if (inspection) {
                inspectionAdapter.upsertMany(state, inspection)
            }
        })
        builder.addCase(updateInspection.fulfilled, (state, action) => {
            const { inspection } = action.payload.entities

            if (inspection) {
                inspectionAdapter.upsertMany(state, inspection)
            }
        })
    },
})

export const {
    selectAll: selectAllInspections,
    selectById: selectInspectionById,
    selectIds: selectInspectionIds,
} = inspectionAdapter.getSelectors<RootState>((state) => state.inspection)

export default inspectionSlice.reducer
