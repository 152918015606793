import React, { JSX } from 'react'
import { Route, useRouteMatch } from 'react-router-dom'
import { IonPage, IonRouterOutlet } from '@ionic/react'
import AimEdit from './AimEdit'

const Aim = (): JSX.Element => {
    const match = useRouteMatch()

    return (
        <IonPage>
            <IonRouterOutlet>
                <Route
                    exact
                    path={`${match.path}/:aimId`}
                    component={AimEdit}
                />
            </IonRouterOutlet>
        </IonPage>
    )
}

export default Aim
