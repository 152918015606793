import React from 'react'
import { Route, useRouteMatch } from 'react-router-dom'
import { IonPage, IonRouterOutlet } from '@ionic/react'
import DocumentationEdit from './DocumentationEdit'

const Documentation = (): JSX.Element => {
    const { path } = useRouteMatch()

    return (
        <IonPage>
            <IonRouterOutlet>
                {/* <Route exact path={match.url} component={PatientList} /> */}
                <Route
                    exact
                    path={`${path}/:documentationId`}
                    component={DocumentationEdit}
                />
                {/* <Route
                        path={`${match.path}/new`}
                        component={DocumentationEdit}
                    /> */}
            </IonRouterOutlet>
        </IonPage>
    )
}

export default Documentation
