import React from 'react'

import ACL from '../../components/ACL'
import Case from './Case'
import RoleEnum from '../../utils/Enum/RoleEnum'

const CaseIndex = (): JSX.Element => {
    return (
        <ACL
            allowedRoles={[
                RoleEnum.ROLE_USER,
                RoleEnum.ROLE_MOD,
                RoleEnum.ROLE_ADMIN,
            ]}
        >
            <Case />
        </ACL>
    )
}

export default CaseIndex
