import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonChip,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonPage,
    IonRow,
    IonText,
    IonTextarea,
    IonTitle,
    IonToolbar,
} from '@ionic/react'
import { FC, useCallback, useEffect } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { useAppDispatch, useAppSelector } from '../../hooks'
import {
    createTestEvaluation,
    fetchTestEvaluationById,
    selectTestEvaluationById,
    updateTestEvaluation,
} from './TestEvaluationSlice'
import moment from 'moment'
import 'moment/locale/de'
import { Formik, FormikErrors, FormikValues } from 'formik'
import { fetchCaseById, selectCaseById } from '../Case/CaseSlice'
import {
    checkmarkOutline,
    informationCircleOutline,
    removeOutline,
} from 'ionicons/icons'
import { selectTestSelectionByCase } from '../Case/selectors'
import TestRow from './TestRow'

const TestEvaluationEdit: FC = () => {
    const match = useRouteMatch<{
        patientId: string
        caseId: string
        testEvaluationId: string
    }>()
    const dispatch = useAppDispatch()
    const { testEvaluationId, patientId, caseId } = match.params
    const isNewTestEvaluation = testEvaluationId === 'new'

    useEffect(() => {
        if (!isNewTestEvaluation)
            dispatch(
                fetchTestEvaluationById({
                    id: +testEvaluationId,
                    patientId,
                    caseId,
                })
            )
    }, [caseId, dispatch, isNewTestEvaluation, patientId, testEvaluationId])

    const testEvaluation = useAppSelector((state) =>
        selectTestEvaluationById(state, parseInt(testEvaluationId))
    )
    const testSelectionByCase = useAppSelector((state) =>
        selectTestSelectionByCase(state, +caseId)
    )
    const caseById = useAppSelector((state) => selectCaseById(state, +caseId))
    const history = useHistory()

    const onSubmit = useCallback(
        async (values: FormikValues) => {
            if (isNewTestEvaluation) {
                await dispatch(
                    createTestEvaluation({ data: values, patientId, caseId })
                )
            } else {
                await dispatch(
                    updateTestEvaluation({
                        id: +testEvaluationId,
                        data: values,
                        patientId,
                        caseId,
                    })
                )
            }
            await dispatch(fetchCaseById({ id: caseId, patientId }))
            history.push(`/patients/${patientId}/cases/${caseId}`)
        },
        [
            caseId,
            dispatch,
            history,
            isNewTestEvaluation,
            patientId,
            testEvaluationId,
        ]
    )

    // const formikProps = useFormik({
    //     onSubmit: onSubmit,
    //     initialValues: (isNewTestEvaluation
    //         ? {
    //               caseId,
    //               icfs: [],
    //               tests: [],
    //               rejectedTests: [],
    //               date: moment().format(),
    //           }
    //         : testEvaluation) as FormikValues,
    // })
    // const { handleSubmit, values, setFieldValue, handleChange } = formikProps

    const handleIcfChipClick = useCallback(
        (
            e: React.MouseEvent,
            icf: string,
            values: FormikValues,
            setFieldValue: (
                field: string,
                value: any,
                shouldValidate?: boolean
            ) => Promise<void | FormikErrors<FormikValues>>
        ) => {
            if (values.icfs.includes(icf)) {
                setFieldValue(
                    'icfs',
                    values.icfs.filter((x: string) => x !== icf)
                )
            } else {
                const value = values?.icfs ? [...values.icfs, icf] : [icf]
                setFieldValue('icfs', value)
            }
        },
        []
    )

    if (!isNewTestEvaluation && !testEvaluation) {
        return null
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton
                            defaultHref={`/patients/${patientId}/cases/${caseId}`}
                        />
                    </IonButtons>
                    <IonTitle>
                        {isNewTestEvaluation
                            ? 'Neue Testauswertung'
                            : 'Testauswertung vom  ' +
                              moment(testEvaluation?.date).format('L')}
                    </IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen color="light">
                <IonCard>
                    <Formik
                        initialValues={
                            isNewTestEvaluation
                                ? {
                                      caseId,
                                      icfs: [],
                                      tests: [],
                                      rejectedTests: [],
                                      date: moment().format(),
                                  }
                                : (testEvaluation as FormikValues)
                        }
                        onSubmit={onSubmit}
                    >
                        {({
                            values,
                            handleSubmit,
                            setFieldValue,
                            handleChange,
                        }) => (
                            <>
                                <IonCardHeader>
                                    <IonCardTitle>Testauswertung</IonCardTitle>
                                    <IonCardSubtitle>
                                        {isNewTestEvaluation
                                            ? moment().format('L')
                                            : moment(values.date).format('L')}
                                    </IonCardSubtitle>
                                </IonCardHeader>
                                <IonCardContent>
                                    <form onSubmit={handleSubmit}>
                                        <IonGrid>
                                            <IonRow>
                                                <IonCol>
                                                    <p>
                                                        Auf welche ICFs bezieht
                                                        sich diese
                                                        Testauswertung
                                                        (optional)?
                                                    </p>
                                                    {caseById?.caseIcfs ? (
                                                        <IonList>
                                                            {caseById.caseIcfs.map(
                                                                (icf) => (
                                                                    <IonChip
                                                                        key={
                                                                            icf
                                                                        }
                                                                        onClick={(
                                                                            e
                                                                        ) =>
                                                                            handleIcfChipClick(
                                                                                e,
                                                                                icf,
                                                                                values,
                                                                                setFieldValue
                                                                            )
                                                                        }
                                                                        color={
                                                                            values.icfs.includes(
                                                                                icf
                                                                            )
                                                                                ? 'success'
                                                                                : undefined
                                                                        }
                                                                        outline={
                                                                            !values.icfs?.includes(
                                                                                icf
                                                                            )
                                                                        }
                                                                        style={{
                                                                            minWidth:
                                                                                '80px',
                                                                            justifyContent:
                                                                                'center',
                                                                        }}
                                                                    >
                                                                        <IonIcon
                                                                            icon={
                                                                                values.icfs?.includes(
                                                                                    icf
                                                                                )
                                                                                    ? checkmarkOutline
                                                                                    : removeOutline
                                                                            }
                                                                        />
                                                                        <IonLabel>
                                                                            {
                                                                                icf
                                                                            }
                                                                        </IonLabel>
                                                                    </IonChip>
                                                                    // <IcfButton
                                                                    //     icf={icf}
                                                                    //     key={icf}
                                                                    //     props={formikProps}
                                                                    //     caseId={+caseId}
                                                                    // />
                                                                )
                                                            )}
                                                        </IonList>
                                                    ) : (
                                                        <IonItem lines="none">
                                                            <IonIcon
                                                                color="primary"
                                                                size="large"
                                                                slot="start"
                                                                icon={
                                                                    informationCircleOutline
                                                                }
                                                            />
                                                            <IonText color="primary">
                                                                Es wurden keine
                                                                ICFs im Fall
                                                                ausgewählt
                                                            </IonText>
                                                        </IonItem>
                                                    )}
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol>
                                                    <p>
                                                        Auf welchen Test bezieht
                                                        sich diese Dokumentation
                                                        (optional)?
                                                    </p>
                                                    {/* <IonChip>
                                            <IonIcon icon={addCircle} />
                                            <IonText>Test hinzufügen</IonText>
                                        </IonChip> */}
                                                    {testSelectionByCase?.tests ? (
                                                        <IonGrid>
                                                            {testSelectionByCase.tests.map(
                                                                (
                                                                    test,
                                                                    index
                                                                ) => (
                                                                    <IonRow
                                                                        key={
                                                                            test
                                                                        }
                                                                    >
                                                                        <TestRow
                                                                            testId={
                                                                                test
                                                                            }
                                                                            values={
                                                                                values
                                                                            }
                                                                            setFieldValue={
                                                                                setFieldValue
                                                                            }
                                                                        />
                                                                    </IonRow>
                                                                )
                                                            )}
                                                        </IonGrid>
                                                    ) : (
                                                        <IonItem lines="none">
                                                            <IonIcon
                                                                color="primary"
                                                                size="large"
                                                                slot="start"
                                                                icon={
                                                                    informationCircleOutline
                                                                }
                                                            />
                                                            <IonText color="primary">
                                                                Es wurden keine
                                                                Tests im Fall
                                                                ausgewählt.
                                                            </IonText>
                                                        </IonItem>
                                                    )}
                                                </IonCol>
                                            </IonRow>
                                        </IonGrid>
                                        <IonItem>
                                            <IonTextarea
                                                name="documentation"
                                                label="Dokumentation"
                                                labelPlacement="floating"
                                                autoGrow={true}
                                                onIonChange={handleChange}
                                                value={
                                                    values.documentation ?? ''
                                                }
                                            ></IonTextarea>
                                        </IonItem>
                                        <IonItem>
                                            <IonTextarea
                                                name="toDoNext"
                                                label="To Do Next"
                                                labelPlacement="floating"
                                                autoGrow={true}
                                                onIonChange={handleChange}
                                                value={values.toDoNext ?? ''}
                                            ></IonTextarea>
                                        </IonItem>
                                        <div
                                            style={{
                                                paddingTop: '16px',
                                                paddingBottom: '16px',
                                                paddingLeft: '16px',
                                            }}
                                        >
                                            <IonButton
                                                onClick={() => handleSubmit()}
                                            >
                                                Speichern
                                            </IonButton>
                                            <IonButton
                                                routerLink={`/patients/${patientId}/cases/${caseId}`}
                                                fill="clear"
                                            >
                                                Abbrechen
                                            </IonButton>
                                        </div>
                                    </form>
                                </IonCardContent>
                            </>
                        )}
                    </Formik>
                </IonCard>
            </IonContent>
        </IonPage>
    )
}

export default TestEvaluationEdit
