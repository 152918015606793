import React, { FC, memo, useMemo } from 'react'
import { selectCaseById } from '../../CaseSlice'
import { useAppSelector } from '../../../../hooks'
import { useParams } from 'react-router-dom'
import MyAccordion from '../../../../components/MyAccordion'
import AddData from '../../../../components/AddData/AddData'
import InspectionOverview from './InspectionOverview'
import { useHistory } from 'react-router'

type InspectionProps = {
    disabled: boolean
    dataLoading: boolean
}

const Inspection: FC<InspectionProps> = ({ disabled, dataLoading }) => {
    const { caseId, patientId } = useParams<{
        caseId: string
        patientId: string
    }>()

    const caseById = useAppSelector((state) =>
        selectCaseById(state, parseInt(caseId))
    )
    const history = useHistory()

    const dataAvailable = useMemo(() => {
        return !!caseById?.inspection
    }, [caseById])

    return (
        <MyAccordion title="Inspektion" showIcon={true} checked={dataAvailable}>
            {dataAvailable ? (
                <InspectionOverview
                    inspectionId={caseById.inspection}
                    onEdit={() => {
                        history.push(
                            `/patients/${patientId}/cases/${caseId}/inspection/`
                        )
                    }}
                />
            ) : (
                <AddData
                    title="Inspektion aufnehmen"
                    disabled={disabled}
                    onClick={() => {
                        history.push(
                            `/patients/${patientId}/cases/${caseId}/inspection`
                        )
                    }}
                />
            )}
        </MyAccordion>
    )
}

export default memo(Inspection)
